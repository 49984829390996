import {roundTo15MinIntervalStart} from '@hconnect/common/utils'
import {dataTestId} from '@hconnect/uikit'
import {Box, useTheme} from '@mui/material'
import {Moment} from 'moment-timezone'
import React, {useCallback, useRef, useState, useMemo, useLayoutEffect} from 'react'

import {GANTT_CHART_ROW_HEIGHT} from '../../constants'
import {useHistoryAssetsQuery} from '../../hooks/api'
import {useCurrentTime} from '../../hooks/useCurrentTime'
import {usePlanRange} from '../../hooks/usePlanRange'
import {usePlantConfig} from '../../hooks/usePlantConfigData'
import {isStartOfDay} from '../../selectors/time'
import {Canvas2D} from '../canvas/Canvas2D'

import {GanttChartAddItem, getMaxScheduleItemEnd} from './GantChartAddItem'

interface GanttChartDataProps {
  visibleHoursList: Moment[]
  displayedHoursOffset: number
  startOfPlan: Moment
  endOfPlan: Moment
  stepWidth: number
  isDisabled: boolean
  nowOffset: number
}

type SelectedGridItem = {x: number; y: number}

export const GanttChartDataGrid: React.FC<GanttChartDataProps> = React.memo(
  ({
    visibleHoursList,
    displayedHoursOffset,
    startOfPlan,
    endOfPlan,
    stepWidth,
    nowOffset,
    isDisabled
  }) => {
    const {data: assets} = useHistoryAssetsQuery({
      timeFrame: [startOfPlan, endOfPlan],
      sorted: true
    })

    const [selectedGridItem, setSelectedGridItem] = useState<SelectedGridItem | undefined>(
      undefined
    )
    const {timezone_id: timezoneId, created_at: createdAt} = usePlantConfig()
    const now = useCurrentTime({timezoneId})
    const nowRounded = useMemo(() => roundTo15MinIntervalStart(now), [now])
    // TODO: remove endOfPlanning with HCP-72218
    const endOfPlanning = usePlanRange({timezoneId, createdAt})[1]

    const addScheduleItemRef = useRef<HTMLDivElement>(null)

    const canvasHeight = assets ? assets.length * GANTT_CHART_ROW_HEIGHT : 0
    const canvasWidth = stepWidth * visibleHoursList.length
    const {palette} = useTheme()
    const canvasHours = visibleHoursList.length

    const canvasRef = useRef<HTMLCanvasElement>(null)

    const onCanvasMouseMove: React.MouseEventHandler<HTMLDivElement> = useCallback(
      (e) => {
        const canvas = canvasRef.current
        if (!canvas) return
        const rect = canvas.getBoundingClientRect()
        const x = e.clientX - rect.left
        const y = e.clientY - rect.top

        const yPart = y / GANTT_CHART_ROW_HEIGHT
        const xPart = (x / canvasWidth) * canvasHours

        const xItem = Math.floor(xPart)
        const yItem = Math.floor(yPart)

        const yPartInsideCell = yPart - yItem
        const xPartInsideCell = xPart - xItem

        const selectedElement: SelectedGridItem | undefined =
          xPartInsideCell > 0.1 &&
          xPartInsideCell < 0.9 &&
          yPartInsideCell > 0.1 &&
          yPartInsideCell < 0.9
            ? {x: xItem, y: yItem}
            : undefined
        setSelectedGridItem(selectedElement)
      },
      [setSelectedGridItem, canvasWidth, canvasHours]
    )
    const onCanvasMouseLeave: React.MouseEventHandler<HTMLDivElement> = useCallback(() => {
      setSelectedGridItem(undefined)
    }, [])

    const getIsCellDisabled = (time: Moment | undefined) => {
      if (isDisabled || !time) {
        return true
      }
      return (
        time.isBefore(nowRounded) ||
        time.isSameOrAfter(getMaxScheduleItemEnd({endOfPlanning, endOfPlan}))
      )
    }

    const canClickGridItem =
      !isDisabled && selectedGridItem && !getIsCellDisabled(visibleHoursList[selectedGridItem.x])

    const selectedGridAssetId = selectedGridItem ? assets?.[selectedGridItem.y].id : undefined

    const relativeCanvasOffset = displayedHoursOffset * stepWidth
    const relativeNowOffset = nowOffset - relativeCanvasOffset

    const renderCanvas = useCallback(
      (ctx: CanvasRenderingContext2D) => {
        const chartHours = visibleHoursList.length
        ctx.clearRect(0, 0, canvasWidth, canvasHeight)
        ctx.fillStyle = palette.grey.A200
        ctx.fillRect(0, 0, canvasWidth, canvasHeight)
        if (relativeNowOffset > 0) {
          ctx.fillStyle = palette.grey[50]
          ctx.fillRect(0, 0, relativeNowOffset, canvasHeight)
        }

        if (!assets?.length) return
        const strokeWidth = 1

        ctx.strokeStyle = palette.grey[400]
        ctx.lineWidth = strokeWidth
        for (let i = 0; i < assets.length; i++) {
          ctx.beginPath()
          ctx.moveTo(0, i * GANTT_CHART_ROW_HEIGHT)
          ctx.lineTo(canvasWidth, i * GANTT_CHART_ROW_HEIGHT)
          ctx.stroke()
        }
        for (let i = 0; i < chartHours; i++) {
          const hour = visibleHoursList[i]
          // drawing start of day lines more visible
          ctx.strokeStyle = palette.grey[isStartOfDay(hour) ? 600 : 300]
          ctx.beginPath()

          const xPos = ((canvasWidth - 2 * strokeWidth) * i) / chartHours - strokeWidth / 2
          ctx.moveTo(xPos, 0)
          ctx.lineTo(xPos, canvasHeight)
          ctx.stroke()
        }
      },
      [canvasHeight, canvasWidth, visibleHoursList, assets?.length, palette, relativeNowOffset]
    )

    useLayoutEffect(() => {
      if (selectedGridItem && addScheduleItemRef.current && selectedGridAssetId !== undefined) {
        addScheduleItemRef.current.style.transform = `translate(${
          selectedGridItem.x * stepWidth
        }px , ${selectedGridItem.y * GANTT_CHART_ROW_HEIGHT}px)`
        addScheduleItemRef.current['data-position'] = `${selectedGridAssetId}_${selectedGridItem.x}`
      }
    }, [selectedGridItem, selectedGridAssetId, stepWidth])

    const selectedGridItemHour = selectedGridItem ? visibleHoursList[selectedGridItem.x] : undefined
    return (
      <Box
        sx={{
          display: 'block',
          height: `${canvasHeight - 2}px`,
          width: `${canvasWidth - 2}px`
        }}
        onMouseMove={onCanvasMouseMove}
        onMouseLeave={onCanvasMouseLeave}
        {...dataTestId('gantt_chart_background_grid')}
      >
        <Canvas2D
          renderCanvas={renderCanvas}
          ref={canvasRef}
          width={canvasWidth}
          height={canvasHeight}
          style={{backgroundColor: palette.grey[200]}}
        />
        {canClickGridItem && selectedGridAssetId !== undefined && selectedGridItemHour && (
          <GanttChartAddItem
            ref={addScheduleItemRef}
            startOfPlan={startOfPlan}
            endOfPlan={endOfPlan}
            endOfPlanning={endOfPlanning}
            stepWidth={stepWidth}
            assetId={selectedGridAssetId}
            selectedGridItem={selectedGridItemHour}
          />
        )}
      </Box>
    )
  }
)

GanttChartDataGrid.displayName = 'GanttChartDataGrid'
