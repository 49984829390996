import {AppInsightsAdapter} from '@hconnect/common/logging'
import {hpTheme, Loader, ErrorBoundary} from '@hconnect/uikit/src/lib2'
import {ThemeProvider} from '@mui/material'
import React, {Suspense} from 'react'
import {createRoot} from 'react-dom/client'
import {BrowserRouter as Router} from 'react-router-dom'

import {checkAndEnableMockIfRequired} from './__mock__/registerMocks'
import {initLocalization} from './localization'
import {Provider} from './shared/components/providers/index.provider'

initLocalization()
checkAndEnableMockIfRequired()

AppInsightsAdapter.init()
AppInsightsAdapter.allowTracking()

const rootElement = document.getElementById('root')
const root = createRoot(rootElement as HTMLElement)

root.render(
  <ThemeProvider theme={hpTheme}>
    <ErrorBoundary
      apology={"We're sorry, something went wrong."}
      resetText={'Click here to start over'}
    >
      <Router>
        <Suspense fallback={<Loader />}>
          <Provider />
        </Suspense>
      </Router>
    </ErrorBoundary>
  </ThemeProvider>
)
