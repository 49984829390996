import {dataTestId} from '@hconnect/uikit'
import {hpTheme} from '@hconnect/uikit/src/lib2'
import {Box, Tooltip} from '@mui/material'
import React, {useState} from 'react'

import {IncomingMaterialStatusToIconMap} from '../../../app/page-stock/materialOrders/materialOrdersIcons'
import {IncomingMaterialStatus} from '../../enums'
import {IncomingMaterial} from '../../selectors/materialOrders'
import {ChartPoint} from '../d3chart/ChartPoint'

import {IncomingMaterialTooltipContent} from './IncomingMaterialTooltipContent'

const {palette} = hpTheme

const incomingMaterialStatusToStyleMap: Record<
  IncomingMaterialStatus,
  {fill: string; color: string}
> = {
  [IncomingMaterialStatus.Cancelled]: {fill: palette.error.light, color: palette.common.white},
  [IncomingMaterialStatus.Delivered]: {fill: palette.success.light, color: palette.common.white},
  [IncomingMaterialStatus.Overdue]: {fill: palette.warning.light, color: palette.common.white},
  [IncomingMaterialStatus.Unknown]: {fill: palette.common.white, color: palette.info.dark},
  [IncomingMaterialStatus.Planned]: {fill: palette.common.white, color: palette.info.dark}
}

interface DeliveredIndicatorProps {
  ordered: number
  delivered: number
}

const DeliveredIndicator: React.FC<DeliveredIndicatorProps> = ({ordered, delivered}) => {
  const deliveredFraction = delivered / ordered
  const circumference = 2 * Math.PI * 8
  return (
    <Box
      component="circle"
      cx={0}
      cy={0}
      sx={{
        fill: 'transparent',
        r: ({spacing}) => spacing(1),
        stroke: ({palette}) => palette.success.light,
        strokeWidth: 16,
        strokeDasharray: `${deliveredFraction * circumference} ${
          circumference - deliveredFraction * circumference
        }`,
        strokeDashoffset: circumference / 4
      }}
    />
  )
}

export const INDICATOR_SIZE = 2

interface IncomingMaterialIndicatorProps {
  x: number
  y: number
  incomingMaterial: IncomingMaterial
}
export const IncomingMaterialIndicator: React.FC<IncomingMaterialIndicatorProps> = ({
  x,
  y,
  incomingMaterial
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const {status, value} = incomingMaterial
  const Icon = IncomingMaterialStatusToIconMap[status]
  const {fill, color} = incomingMaterialStatusToStyleMap[status]
  return (
    <Tooltip
      open={isOpen}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      title={
        <IncomingMaterialTooltipContent
          incomingMaterial={incomingMaterial}
          closeTooltip={() => setIsOpen(false)}
        />
      }
    >
      <ChartPoint
        x={x}
        y={y}
        circleSx={{
          r: ({spacing}) => spacing(INDICATOR_SIZE),
          stroke: 'none',
          overflow: 'hidden',
          fill,
          filter: 'drop-shadow(0px 4px 14px rgba(0, 0, 0, 0.12))'
        }}
        {...dataTestId(`incoming_material_${status}`)}
      >
        {status === IncomingMaterialStatus.Overdue && (
          <DeliveredIndicator ordered={incomingMaterial.order.amount} delivered={value} />
        )}
        <Icon component="g" transform={'translate(-12,-12)'} sx={{color}} />
      </ChartPoint>
    </Tooltip>
  )
}
