import React from 'react'

import {useLoginDetails} from '../../../../shared/components/providers/LoginProvider'
import {useScheduleCostAvoidanceDialog} from '../../../../shared/components/providers/ScheduleCostAvoidanceDialogProvider'
import {PlannerFeature} from '../../../../shared/enums'
import {trackPlannerEvent} from '../../../../shared/helpers/trackPlannerEvents'
import {useSubmitSchedule} from '../../../../shared/hooks/api/schedules/useSubmitSchedule'
import {usePlannerFeature} from '../../../../shared/hooks/usePlannerFeature'
import {useUrlParam} from '../../../../shared/hooks/useUrlParam'
import {Electricity, Schedule} from '../../../../shared/interfaces/api'
import {usePlanningChartElectricityData} from '../PlanningChartElectricityDataProvider'
import {usePlanningChartScheduleData} from '../PlanningChartScheduleDataProvider'

import {ElectricityPurchaseConfirmation} from './ElectricityPurchaseConfirmation'
import {SubmissionButton} from './SubmissionButton'

interface SubmitPlanSectionProps {
  electricity: Electricity | undefined
  isSubmitAvailable: boolean
}

export const SubmitPlanSection: React.FC<SubmitPlanSectionProps> = React.memo(
  ({isSubmitAvailable, electricity}) => {
    const plantCode = useUrlParam('plantCode')

    const {loginDetails} = useLoginDetails()
    if (!loginDetails) throw new Error('Login details are missing')

    const {mutate: submitSchedule} = useSubmitSchedule()
    const {schedule} = usePlanningChartScheduleData()
    const {peakLoadWindows} = usePlanningChartElectricityData()
    const isCostAvoidanceEnabled = usePlannerFeature(PlannerFeature.CostAvoidance)
    const {setIsDialogOpen} = useScheduleCostAvoidanceDialog()

    const [showConfirmation, setShowConfirmation] = React.useState(false)

    const isSubmitDisabled = peakLoadWindows && peakLoadWindows.some((plw) => plw.violated)

    const handleSubmitPlan = (schedule: Schedule) => {
      trackPlannerEvent('onSubmitPlanClick', {
        userId: loginDetails.userId,
        plantId: plantCode
      })
      submitSchedule(
        {
          plantCode,
          scheduleId: schedule.scheduleId,
          updatedById: schedule.lastSaved.savedById,
          updatedOn: schedule.lastSaved.savedAt
        },
        {
          onSuccess: isCostAvoidanceEnabled ? () => setIsDialogOpen(true) : undefined
        }
      )
    }

    return (
      <>
        {showConfirmation && electricity && (
          <ElectricityPurchaseConfirmation
            show={showConfirmation}
            electricity={electricity}
            onClose={(submit: boolean) => {
              if (submit && schedule) {
                handleSubmitPlan(schedule)
              }
              setShowConfirmation(false)
            }}
          />
        )}
        {isSubmitAvailable && (
          <SubmissionButton
            id="submit-plan-button"
            buttonLabel="planning.submitSchedule"
            buttonTestId="button-schedule-save-and-submit"
            disabled={isSubmitDisabled}
            onClick={() => setShowConfirmation(true)}
          />
        )}
      </>
    )
  }
)
