import {dataTestId} from '@hconnect/uikit'
import {formatTimeZoneDate} from '@hconnect/uikit/src/common'
import {DeleteOutline, DeleteOutlined, EditOutlined} from '@mui/icons-material'
import {Box, Typography} from '@mui/material'
import Markdown from 'markdown-to-jsx'
import {Moment} from 'moment-timezone'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {KebabMenu, MenuAction} from '../../../../shared/components/KebabMenu'
import {useConfirmDialog} from '../../../../shared/components/providers/ConfirmDialogProvider'
import {useSelectedAssetId} from '../../../../shared/components/providers/SelectedAssetCommentProvider'
import {useSelectedScheduleItemId} from '../../../../shared/components/providers/SelectedScheduleItemProvider'
import {CommentsCategory} from '../../../../shared/enums'
import {useHistoryAssetsByIdQuery} from '../../../../shared/hooks/api'
import {useDeleteComment} from '../../../../shared/hooks/api/comments/useDeleteComment'
import {useCommentsPermissions} from '../../../../shared/hooks/permissions'
import {usePlantConfig} from '../../../../shared/hooks/usePlantConfigData'
import {useUrlParam} from '../../../../shared/hooks/useUrlParam'
import {Comment, ScheduleItem} from '../../../../shared/interfaces/api'
import {HCEMPalette} from '../../../../shared/pallette'
import {usePlanningChartScheduleData} from '../PlanningChartScheduleDataProvider'

interface CommentContentProps {
  isReadOnly: boolean
  comment: Comment
  startOfPlan: Moment
  endOfPlan: Moment
}

export const CommentContent: React.FC<CommentContentProps> = ({
  isReadOnly,
  startOfPlan,
  endOfPlan,
  comment: {
    value,
    updatedBy,
    updatedOn,
    createdBy,
    createdOn,
    commentsCategoryInstanceId: instanceId,
    commentsCategory,
    commentId
  }
}) => {
  const {
    t,
    i18n: {language}
  } = useTranslation()
  const plantCode = useUrlParam('plantCode')
  const {timezone_id: timezoneId} = usePlantConfig()
  const {schedule, assetDataByScheduleItemId} = usePlanningChartScheduleData()
  const {setScheduleItemId} = useSelectedScheduleItemId()
  const {setAssetId} = useSelectedAssetId()
  const {mutate: deleteComment} = useDeleteComment()
  const {data: assetsById} = useHistoryAssetsByIdQuery({timeFrame: [startOfPlan, endOfPlan]})
  const scheduleDict = schedule?.schedules || {}
  const scheduleItems = Object.values(scheduleDict)
  const {openDialog} = useConfirmDialog()

  const {canEditComments, canDeleteComments} = useCommentsPermissions(commentsCategory)

  const createUpdateInfo = `${updatedBy || createdBy}, ${formatTimeZoneDate(
    updatedOn || createdOn,
    timezoneId,
    'DD MMM LT',
    language
  )}`

  const getScheduleItemForComment = (commentsCategoryInstanceId: string) => {
    const scheduleItem = scheduleItems.find((item) => item.id === commentsCategoryInstanceId)
    if (!scheduleItem) throw new Error('Missing schedule item for comment')
    return scheduleItem
  }
  const getAssetName = (assetId: string | number) =>
    assetsById?.[assetId].name ?? t('common.loading')
  const getStartAndEndTimes = ({start, end}: ScheduleItem) => {
    const formatTime = (time: string) => formatTimeZoneDate(time, timezoneId, 'HH:mm', language)
    return `(${formatTime(start)} - ${formatTime(end)})`
  }

  const getCommentTitle = () => {
    switch (commentsCategory) {
      case CommentsCategory.Assets:
        return getAssetName(instanceId)
      case CommentsCategory.AssetOperationTimes: {
        const scheduleItem = getScheduleItemForComment(instanceId)
        const operationMode = assetDataByScheduleItemId?.[scheduleItem.id].operationMode
        return `${getAssetName(scheduleItem.assetId)} / ${
          operationMode?.name ?? t('common.loading')
        } ${getStartAndEndTimes(scheduleItem)}`
      }
      default:
        throw new Error('Unsupported comment category')
    }
  }

  const onEdit = () => {
    switch (commentsCategory) {
      case CommentsCategory.Assets:
        return setAssetId(Number(instanceId))
      case CommentsCategory.AssetOperationTimes: {
        const {id} = getScheduleItemForComment(instanceId)
        return setScheduleItemId(id)
      }
      default:
        return new Error('Unsupported comment category')
    }
  }

  const onDelete = () => {
    openDialog({
      title: t('planning.deleteComment'),
      description: t('planning.deleteCommentConfirmation'),
      mainAction: {
        color: 'error',
        text: t('common.delete'),
        icon: <DeleteOutline />,
        onAction: () =>
          deleteComment({
            plantCode,
            commentsCategory,
            commentsCategoryInstanceId: instanceId,
            commentId
          })
      }
    })
  }

  const menuActions: MenuAction[] = [
    {
      title: t('common.edit'),
      testId: 'edit_button',
      icon: <EditOutlined fontSize="small" />,
      onClick: onEdit,
      isDisabled: !canEditComments
    },
    {
      title: t('common.delete'),
      testId: 'delete_button',
      icon: <DeleteOutlined fontSize="small" />,
      onClick: onDelete,
      isDisabled: !canDeleteComments
    }
  ]

  return (
    <Box
      sx={{
        display: 'flex',
        position: 'relative',
        px: 2,
        pt: 4,
        pb: 2,
        '&:hover': {backgroundColor: HCEMPalette.oceanTeal}
      }}
      {...dataTestId('comment_content')}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          pr: 2,
          color: ({palette}) => palette.common.white
        }}
      >
        <Typography variant="h4" {...dataTestId('comment_title')}>
          {getCommentTitle()}
        </Typography>
        <Box mt={1}>
          <Markdown {...dataTestId('comment_value')}>{value}</Markdown>
        </Box>
        <Box mt={1}>
          <Typography variant="body1" color="secondary">
            {createUpdateInfo}
          </Typography>
        </Box>
      </Box>
      {!isReadOnly && (
        <KebabMenu sx={{position: 'absolute', mr: 1, right: 0}} actions={menuActions} />
      )}
    </Box>
  )
}
