import {pick} from 'lodash'

import {compressFile} from '../files'
import {User, AttachmentFile, EquipmentData} from '../types'

export const appendUser = (formData: FormData, user: User, name: string) => {
  for (const key in user) {
    formData.append(`${name}[${key}]`, user[key] as string)
  }
}

export const attachmentFilesToFormData = async (value, formData: FormData) => {
  const attachmentFiles: File[] = await Promise.all(
    (value as AttachmentFile[])
      .filter(Boolean)
      .map((atm: AttachmentFile) => compressFile(atm.originalFile as File))
  )
  attachmentFiles.forEach((file) => {
    formData.append('attachments', file, file.name)
  })
}

export const appendEquipmentData = (
  formData: FormData,
  equipment: EquipmentData,
  mainEquipment?: boolean
) => {
  for (const key in pick(equipment, ['id', 'idType', 'text'])) {
    mainEquipment
      ? formData.append(`mainEquipment[${key}]`, equipment[key] as string)
      : formData.append(`equipment[${key}]`, equipment[key] as string)
  }
}
