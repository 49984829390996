import {CardBox} from '@hconnect/uikit/src/lib2'
import {Box} from '@mui/material'
import {Moment} from 'moment-timezone'
import React from 'react'

import {useHistoryAssetsQuery} from '../../shared/hooks/api'
import {NumberRange} from '../../shared/selectors/time'
import {PlanningChartElectricityDataProvider} from '../page-planning/dailyPlanning/PlanningChartElectricityDataProvider'
import {PlanningChartScheduleDataProvider} from '../page-planning/dailyPlanning/PlanningChartScheduleDataProvider'
import {PlanningChartStartEndProvider} from '../page-planning/dailyPlanning/PlanningChartStartEndProvider'

import {OptimizerDetailsChartContainer} from './optimizerDetails/OptimizerDetailsChartContainer'
import {OptimizerDetailsKPIs} from './OptimizerDetailsKPIs'

interface LayoutOptimizerPlanDetailsProps {
  startOfPlan: Moment
  endOfPlan: Moment
  updateSliderDefaultRange: (range: number) => void
  sliderDefaultRange: number
  sliderMinMaxRange: NumberRange
  isOptimized?: boolean
}

export const LayoutOptimizerPlanDetails: React.FC<LayoutOptimizerPlanDetailsProps> = ({
  startOfPlan,
  endOfPlan,
  isOptimized = false,
  updateSliderDefaultRange,
  sliderDefaultRange,
  sliderMinMaxRange
}) => {
  const {data: sortedAssets} = useHistoryAssetsQuery({
    timeFrame: [startOfPlan, endOfPlan],
    sorted: true
  })

  return (
    <>
      <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
        <CardBox sx={{mb: 3, flexBasis: '50%'}}>
          <OptimizerDetailsKPIs
            startOfPlan={startOfPlan}
            endOfPlan={endOfPlan}
            planType={isOptimized ? 'optimized' : 'manual'}
          />
        </CardBox>
      </Box>
      <PlanningChartStartEndProvider
        sliderDefaultRange={sliderDefaultRange}
        sliderMinMaxRange={sliderMinMaxRange}
        updateSliderDefaultRange={updateSliderDefaultRange}
        startOfPlan={startOfPlan}
        endOfPlan={endOfPlan}
        boundary={[startOfPlan, endOfPlan]}
      >
        <PlanningChartScheduleDataProvider isOptimized={isOptimized}>
          <PlanningChartElectricityDataProvider>
            {sortedAssets && <OptimizerDetailsChartContainer sortedAssets={sortedAssets} />}
          </PlanningChartElectricityDataProvider>
        </PlanningChartScheduleDataProvider>
      </PlanningChartStartEndProvider>
    </>
  )
}
