import {NotificationProvider} from '@hconnect/uikit/src/common'
import {ErrorBoundary} from '@hconnect/uikit/src/lib2'
import {CircularProgress, CssBaseline} from '@mui/material'
import React, {Suspense} from 'react'
import {HelmetProvider} from 'react-helmet-async'
import {useTranslation} from 'react-i18next'
import {useNavigate} from 'react-router-dom'

import {App} from '../../../app/App'
import {getUrl, ROUTES} from '../../../routing'
import {Log} from '../../Log'
import {HCHelmet} from '../helmets/HCHelmet'
import {MaintenanceSwitch} from '../MaintenanceSwitch'

import {ConfirmDialogProvider} from './ConfirmDialogProvider'
import {FullScreenProvider} from './FullscreenProvider'
import {LocalStorageSettingsProvider} from './LocalStorageSettingsProvider'
import {LoginProvider} from './LoginProvider'
import {QueryProvider} from './QueryProvider'

export const Provider: React.FC = () => {
  const navigate = useNavigate()

  const {t} = useTranslation()
  const handleResetError = () => {
    navigate(getUrl(ROUTES.BASE.path, {}))
    Log.context('Index.provider', 'Error Boundary').info('Error Boundary Reset Click')
  }

  return (
    <Suspense fallback={<CircularProgress />}>
      <ErrorBoundary
        apology={t('error.apology')}
        resetText={t('error.startOver')}
        onReset={handleResetError}
      >
        <CssBaseline />
        <HelmetProvider>
          <HCHelmet />
          <MaintenanceSwitch>
            <QueryProvider>
              <FullScreenProvider>
                <ConfirmDialogProvider>
                  <NotificationProvider>
                    <LoginProvider>
                      <LocalStorageSettingsProvider>
                        <App />
                      </LocalStorageSettingsProvider>
                    </LoginProvider>
                  </NotificationProvider>
                </ConfirmDialogProvider>
              </FullScreenProvider>
            </QueryProvider>
          </MaintenanceSwitch>
        </HelmetProvider>
      </ErrorBoundary>
    </Suspense>
  )
}
