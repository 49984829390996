import {roundTo15MinIntervalStart} from '@hconnect/common/utils'
import {dataTestId} from '@hconnect/uikit'
import {MenuItem, TextField, Stack} from '@mui/material'
import moment, {Moment} from 'moment-timezone'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {getMaxScheduleItemEnd} from '../../../../../../shared/components/ganttChart/GantChartAddItem'
import {getGanttChartEndOfPlanning} from '../../../../../../shared/components/ganttChart/ganttChartEndOfPlanning'
import {useCurrentTimeRounded} from '../../../../../../shared/hooks/useCurrentTimeRounded'
import {usePlantConfig} from '../../../../../../shared/hooks/usePlantConfigData'
import type {Iso8601, ScheduleItem} from '../../../../../../shared/interfaces/api'
import {
  DateTimeFragment,
  convertToDate,
  convertToDateTime,
  convertToTime,
  getSelectableDateTime,
  splitToIntervals
} from '../../../../../../shared/selectors/time'

import {DatetimeSelect} from './DatetimeSelect'

const getList15Min = (start: Moment, end: Moment, timezoneId: string) =>
  splitToIntervals(start, end, 15).map((i) => ({
    key: convertToTime(moment.utc(i.start).tz(timezoneId)),
    label: moment.utc(i.start).tz(timezoneId).format('HH:mm')
  }))

interface ScheduleItemStartEndSelectProps {
  endOfPlan: Moment
  updateScheduleItem: (item: ScheduleItem) => void
  scheduleItem: ScheduleItem
}

export const ScheduleItemStartEndSelect: React.FC<ScheduleItemStartEndSelectProps> = ({
  scheduleItem,
  updateScheduleItem,
  endOfPlan
}) => {
  const {t} = useTranslation()
  const {timezone_id: timezoneId} = usePlantConfig()
  const nowRounded = useCurrentTimeRounded({timezoneId, roundingFn: roundTo15MinIntervalStart})

  // TODO: remove endOfPlanning with HCP-72218
  const endOfPlanning = getGanttChartEndOfPlanning(nowRounded)

  const currentStart: Moment = moment.utc(scheduleItem.start).tz(timezoneId)
  const currentEnd: Moment = moment.utc(scheduleItem.end).tz(timezoneId)

  const maxRestrictedEnd = getMaxScheduleItemEnd({endOfPlanning, endOfPlan})

  const selectableStartTimes = getList15Min(
    moment.min([currentStart, nowRounded]),
    moment.min([currentStart.clone().endOf('day'), currentEnd]),
    timezoneId
  )

  const selectableEndTimes = getList15Min(
    moment.max([
      moment.min([nowRounded, currentStart.clone().add(15, 'minutes')]),
      currentEnd.clone().startOf('day')
    ]),
    currentEnd.clone().endOf('day'),
    timezoneId
  )

  const handleDateChanges = (value: string, type: DateTimeFragment): void => {
    const newStart: Iso8601 = convertToDateTime(
      type === 'startDate' ? value : convertToDate(currentStart),
      type === 'startTime' ? value : convertToTime(currentStart)
    )
    let newEnd: Iso8601 = convertToDateTime(
      type === 'endDate' ? value : convertToDate(currentEnd),
      type === 'endTime' ? value : convertToTime(currentEnd)
    )

    if (moment.utc(newStart) >= moment.utc(newEnd)) {
      newEnd = moment.utc(newStart).clone().add(15, 'minutes').toISOString()
    }
    const updatedScheduleItem: ScheduleItem = {
      ...scheduleItem,
      start: newStart,
      end: newEnd
    }
    updateScheduleItem(updatedScheduleItem)
  }

  const isStartEditDisabled =
    currentStart.isBefore(nowRounded) || currentEnd.isAfter(maxRestrictedEnd)
  const isEndEditDisabled =
    currentEnd.isSameOrBefore(nowRounded) || currentEnd.isAfter(maxRestrictedEnd)

  const getDaysRestrictedAtStart = getSelectableDateTime(
    moment.min([nowRounded, currentStart]),
    isStartEditDisabled ? endOfPlan : maxRestrictedEnd,
    timezoneId,
    'start'
  )
  const getDaysRestrictedAtEnd = getSelectableDateTime(
    moment.min([nowRounded, currentStart]),
    isStartEditDisabled ? endOfPlan : maxRestrictedEnd,
    timezoneId,
    'end'
  )

  return (
    <Stack spacing={1.5}>
      <Stack spacing={1.5} direction={{sm: 'row', xs: 'column'}}>
        <TextField
          {...dataTestId('schedule_edit_start_time')}
          select
          label={t('planning.startTime')}
          value={convertToTime(currentStart)}
          fullWidth
          onChange={(e) => handleDateChanges(e.target.value, 'startTime')}
          variant="outlined"
          disabled={isStartEditDisabled}
        >
          {selectableStartTimes.map(({key, label}) => (
            <MenuItem key={key} value={key} {...dataTestId(key)}>
              {label}
            </MenuItem>
          ))}
        </TextField>
        <DatetimeSelect
          type="startDate"
          value={scheduleItem.start}
          restrictionDatetime={scheduleItem.end}
          timezoneId={timezoneId}
          handleDateChanges={handleDateChanges}
          menuOptions={getDaysRestrictedAtEnd}
          isDisabled={isStartEditDisabled}
          {...dataTestId('schedule_edit_start_date')}
        />
      </Stack>
      <Stack spacing={1.5} direction={{sm: 'row', xs: 'column'}}>
        <TextField
          {...dataTestId('schedule_edit_end_time')}
          select
          label={t('planning.endTime')}
          value={convertToTime(currentEnd)}
          fullWidth
          onChange={(event) => handleDateChanges(event.target.value, 'endTime')}
          variant="outlined"
          disabled={isEndEditDisabled}
        >
          {selectableEndTimes.map((option) => (
            <MenuItem key={option.key} value={option.key} {...dataTestId(option.key)}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <DatetimeSelect
          type="endDate"
          value={scheduleItem.end}
          restrictionDatetime={scheduleItem.start}
          timezoneId={timezoneId}
          handleDateChanges={handleDateChanges}
          menuOptions={getDaysRestrictedAtStart}
          isDisabled={isEndEditDisabled}
          {...dataTestId('schedule_edit_end_date')}
        />
      </Stack>
    </Stack>
  )
}
