import {retry, retryMutation} from '@hconnect/common/query/queryMutationRetry'
import {DefaultOptions, QueryClient, QueryClientProvider} from '@tanstack/react-query'
import {ReactQueryDevtools} from '@tanstack/react-query-devtools'
import {AxiosError} from 'axios'
import React from 'react'
import {useErrorHandler} from 'react-error-boundary'

const defaults: DefaultOptions = {
  queries: {
    retry,
    refetchOnWindowFocus: false,
    staleTime: Infinity
  },
  mutations: {
    retry: retryMutation
  }
}
export const hcemQueryClient = new QueryClient({defaultOptions: defaults})

export const QueryProvider = ({children}: {children: React.ReactNode}) => {
  const raiseError = useErrorHandler()

  const onError = (err) => {
    const axiosError = err as AxiosError<Record<string, string>>
    const response = axiosError.response

    if (!response) {
      raiseError(err)
      return
    }

    const statusCode = response?.status
    const statusText = response?.data?.status ?? response?.statusText
    const url = response?.request?.responseURL

    const message = [
      `Error occurred querying ${url}`,
      `${statusCode}: ${statusText}`,
      response?.data?.detail
    ].join('\n')
    raiseError(new Error(message))
  }

  hcemQueryClient.setDefaultOptions({
    queries: {...defaults.queries, onError},
    mutations: {onError}
  })

  return (
    <QueryClientProvider client={hcemQueryClient}>
      {children}
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  )
}
