import {MomentRange} from '../../../selectors/time'
import {useUrlParam} from '../../useUrlParam'
import {usePlannerQuery} from '../usePlannerQuery'

interface ScheduleQueryParams {
  isDisabled?: boolean
  range: MomentRange
}

export const useScheduleQuery = ({
  isDisabled = false,
  range: [startOfPlan, endOfPlan]
}: ScheduleQueryParams) => {
  const plantCode = useUrlParam('plantCode')

  return usePlannerQuery(
    'schedule',
    [{plantCode, start: startOfPlan.toISOString(), end: endOfPlan.toISOString()}],
    {enabled: !isDisabled}
  )
}
