import {Material, MaterialSource} from '@hconnect/common/types'
import {dataTestId} from '@hconnect/uikit'
import {Card, CardTitle, CardProps} from '@hconnect/uikit/src/lib2'
import {LocalShippingOutlined, LocationCityOutlined} from '@mui/icons-material'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import {Box, Tooltip} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {useNavigate} from 'react-router'

import {PLANT_ROUTES, RoutingState, getUrl} from '../../../routing'
import {useLoginDetails} from '../../../shared/components/providers/LoginProvider'
import {PlannerFeature, PlannerTrackedFeatures} from '../../../shared/enums'
import {trackPlannerEvent} from '../../../shared/helpers/trackPlannerEvents'
import {usePermission} from '../../../shared/hooks/permissions/usePermission'
import {usePlannerFeature} from '../../../shared/hooks/usePlannerFeature'
import {usePlantConfig} from '../../../shared/hooks/usePlantConfigData'
import {useUrlParam} from '../../../shared/hooks/useUrlParam'

interface StockCard extends CardProps {
  title: React.ReactNode
  additionalHeader?: React.ReactNode
  material?: Material
  page: 'storage' | 'material'
  colSpan?: number
  children: React.ReactNode
}

export const StockCard: React.FC<StockCard> = ({
  title,
  additionalHeader,
  colSpan,
  page,
  material,
  children,
  ...rest
}) => {
  const {t} = useTranslation()
  const navigate = useNavigate()
  const plantCode = useUrlParam('plantCode')
  const {country, name: plantName} = usePlantConfig()
  const {loginDetails} = useLoginDetails()
  if (!loginDetails) {
    throw new Error('Login details are missing')
  }

  const canViewMaterialOrders = usePermission('VIEW_MATERIAL_ORDERS')
  const isRawMaterialsPlanningFeatureAvailable = usePlannerFeature(
    PlannerFeature.RawMaterialsPlanning
  )

  const canGoToBoughtToVendorPage =
    !!material &&
    material.source === MaterialSource.BoughtFromVendor &&
    canViewMaterialOrders &&
    isRawMaterialsPlanningFeatureAvailable

  const boughtFromVendorIsDisabled = canGoToBoughtToVendorPage && !material?.products.length

  const handleCardClick =
    canGoToBoughtToVendorPage && !boughtFromVendorIsDisabled
      ? () => {
          trackPlannerEvent('userEntersMaterialPlan', {
            userId: loginDetails.userId,
            plantId: plantCode,
            country,
            plantName,
            feature: PlannerTrackedFeatures.RawMaterialsPlanning
          })
          navigate(
            getUrl(PLANT_ROUTES.STOCK.MATERIALS.ORDERS.path, {
              plantCode,
              materialId: String(material.id)
            }),
            {
              state: {
                materialsVisitedFrom: page
              } as RoutingState
            }
          )
        }
      : undefined

  const card = (
    <Card
      {...rest}
      onClick={handleCardClick}
      sx={{
        cursor: canGoToBoughtToVendorPage
          ? boughtFromVendorIsDisabled
            ? 'help'
            : 'pointer'
          : 'default',
        gridColumn: `span ${colSpan}`
      }}
      headerContent={
        <Box sx={{display: 'flex', flexDirection: 'column'}}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <Box sx={{display: 'flex', flexDirection: 'column'}}>
              <CardTitle sx={{mb: 0.5}} {...dataTestId('stock_card_title')}>
                {title}
              </CardTitle>
              {material?.source === MaterialSource.BoughtFromVendor && (
                <Box sx={{display: 'flex', direction: 'row'}}>
                  <Box sx={{mr: 1}}>
                    <LocalShippingOutlined />
                  </Box>
                  <Box>{t('stock.boughtFromVendor')}</Box>
                </Box>
              )}
              {material?.source === MaterialSource.ProducedInPlant && (
                <Box sx={{display: 'flex', flexDirection: 'row'}}>
                  <Box sx={{mr: 1}}>
                    <LocationCityOutlined />
                  </Box>
                  <Box>{t('stock.producedInPlant')}</Box>
                </Box>
              )}
            </Box>
            {canGoToBoughtToVendorPage && (
              <ArrowForwardIosIcon
                fontSize="small"
                color="secondary"
                {...dataTestId('show_more_icon')}
              />
            )}
          </Box>
          {additionalHeader}
        </Box>
      }
    >
      {children}
    </Card>
  )

  return canGoToBoughtToVendorPage && boughtFromVendorIsDisabled ? (
    <Tooltip
      title={t('stock.boughtFromVendorDisabledTooltip')}
      followCursor={true}
      placement="bottom"
    >
      <span>{card}</span>
    </Tooltip>
  ) : (
    card
  )
}
