import {dataTestId} from '@hconnect/uikit/'
import {HPButton} from '@hconnect/uikit/src/lib2'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import {
  Box,
  Grid,
  Typography,
  Paper,
  Button,
  Popper,
  IconButton,
  ClickAwayListener,
  Stack,
  styled
} from '@mui/material'
import moment, {Moment} from 'moment-timezone'
import React, {useRef, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {toPixel} from '../../../shared/helpers/utils'

const MonthChangeIconButton = styled(IconButton)(({theme}) => ({
  color: theme.palette.common.white,
  '&.Mui-disabled .MuiSvgIcon-root': {
    color: theme.palette.grey[500]
  }
}))

interface MonthTimerangeSelectProps {
  selectedRange: [Moment, Moment]
  setSelectedRange: (range: [Moment, Moment]) => void
  validDateRange?: [Moment, Moment]
}

export const MonthRangeSelect: React.FC<MonthTimerangeSelectProps> = ({
  selectedRange,
  setSelectedRange,
  validDateRange
}) => {
  const {
    t,
    i18n: {language}
  } = useTranslation()
  const [open, setOpen] = useState(false)
  const anchorEl = useRef(null) // Create a ref for the anchor element

  const [start, end] = selectedRange

  const getSelectedYear = () => {
    if (start.date() < start.daysInMonth() / 2) {
      return start.year()
    }
    return end.year()
  }

  const isYearDisabled = (year: number) => {
    if (!validDateRange) {
      return false
    }
    return year < validDateRange[0].year() || year > validDateRange[1].year()
  }

  const isMonthDisabled = (monthIndex: number, year: number) => {
    if (!validDateRange) {
      return false
    }
    if (isYearDisabled(year)) {
      return true
    }
    if (getSelectedYear() === validDateRange[0].year()) {
      return monthIndex < validDateRange[0].month()
    }
    if (getSelectedYear() === validDateRange[1].year()) {
      return monthIndex > validDateRange[1].month()
    }
    return false
  }

  const isMonthSelected = (monthIndex: number, selectedYear: number) => {
    if (getSelectedYear() !== selectedYear) {
      return false
    }
    return (
      (start.month() === monthIndex && start.date() < start.daysInMonth() / 2) ||
      (end.month() === monthIndex && end.date() >= end.daysInMonth() / 2)
    )
  }

  const [selectedYear, setSelectedYear] = useState<number>(getSelectedYear())

  const handleOpen = () => {
    setSelectedYear(getSelectedYear())
    setOpen(true)
  }

  const handleClose = () => {
    setSelectedYear(getSelectedYear())
    setOpen(false)
  }

  const handleMonthClick = (monthIndex: number, year: number) => {
    const newRange: [Moment, Moment] = [
      start.clone().startOf('month').month(monthIndex).year(year),
      start
        .clone()
        .startOf('month')
        .year(year)
        .month(monthIndex + 1)
    ]
    setSelectedRange(newRange)
    handleClose()
  }

  const handleMonthArrowClick = (direction: 'left' | 'right') => {
    const newRange: [Moment, Moment] = [
      start
        .clone()
        .add(direction === 'left' ? -1 : 1, 'month')
        .startOf('month'),
      end
        .clone()
        .add(direction === 'left' ? -1 : 1, 'month')
        .startOf('month')
    ]
    setSelectedRange(newRange)
  }

  const handleYearArrowClick = (direction: 'left' | 'right') => {
    setSelectedYear((selectedYear) => (direction === 'left' ? selectedYear - 1 : selectedYear + 1))
  }

  const getSelectedRangeText = () => {
    const isRangeBetweenMonth = start.month() !== end.clone().subtract(1, 'day').month()
    const isRangeBetweenYear = start.year() !== end.year()
    if (isRangeBetweenMonth) {
      if (isRangeBetweenYear) {
        return `${start.locale(language).format('MMMM YYYY')} / ${moment
          .utc(selectedRange[1])
          .locale(language)
          .format('MMMM YYYY')}`
      }
      return `${start.locale(language).format('MMMM')} / ${end
        .locale(language)
        .format('MMMM YYYY')}`
    }
    return `${start.locale(language).format('MMMM YYYY')}`
  }

  return (
    <Box>
      <HPButton
        ref={anchorEl}
        // to insure correct DOM nesting, use div as component
        component="div"
        backgroundMode="medium"
        startIcon={
          <MonthChangeIconButton
            disabled={isMonthDisabled(
              start.clone().add(-1, 'month').month(),
              start.clone().add(-1, 'month').year()
            )}
            onClick={() => handleMonthArrowClick('left')}
            {...dataTestId('month_range_select_month_back')}
          >
            <ArrowBackIosIcon />
          </MonthChangeIconButton>
        }
        endIcon={
          <MonthChangeIconButton
            disabled={isMonthDisabled(
              end.clone().add(1, 'month').month(),
              end.clone().add(1, 'month').year()
            )}
            onClick={() => handleMonthArrowClick('right')}
            {...dataTestId('month_range_select_month_forward')}
          >
            <ArrowForwardIosIcon />
          </MonthChangeIconButton>
        }
        sx={{py: toPixel(6)}}
        {...dataTestId('month_range_select')}
      >
        <Stack
          onClick={handleOpen}
          sx={{alignItems: 'center', minWidth: ({spacing}) => spacing(16)}}
        >
          <Typography variant="caption" sx={{color: ({palette}) => palette.grey[300]}}>
            {t('common.timeRange')}
          </Typography>
          <Typography variant="body1" {...dataTestId('month_range_select_selected_range')}>
            {getSelectedRangeText()}
          </Typography>
        </Stack>
      </HPButton>
      <Popper open={open} anchorEl={anchorEl.current} placement="bottom">
        <ClickAwayListener onClickAway={handleClose}>
          <Paper sx={{p: 2, maxWidth: ({spacing}) => spacing(60)}} elevation={8}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                  <IconButton
                    color="inherit"
                    disabled={isYearDisabled(selectedYear - 1)}
                    onClick={() => handleYearArrowClick('left')}
                    {...dataTestId('month_range_select_year_back')}
                  >
                    <ArrowBackIcon />
                  </IconButton>
                  <Typography variant="h4" {...dataTestId('month_range_select_selected_year')}>
                    {selectedYear}
                  </Typography>
                  <IconButton
                    color="inherit"
                    disabled={isYearDisabled(selectedYear + 1)}
                    onClick={() => handleYearArrowClick('right')}
                    {...dataTestId('month_range_select_year_forward')}
                  >
                    <ArrowForwardIcon />
                  </IconButton>
                </Box>
              </Grid>
              {moment.months().map((month, monthIndex) => (
                <Grid item xs={4} key={monthIndex}>
                  <Button
                    disabled={isMonthDisabled(monthIndex, selectedYear)}
                    variant={isMonthSelected(monthIndex, selectedYear) ? 'contained' : 'text'}
                    onClick={() => handleMonthClick(monthIndex, selectedYear)}
                    {...dataTestId(`month_range_select_month_${monthIndex}`)}
                  >
                    {moment.utc().month(monthIndex).format('MMMM')}
                  </Button>
                </Grid>
              ))}
            </Grid>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </Box>
  )
}
