import {dataTestId as spreadDataTestId} from '@hconnect/uikit'
import {dateFormatter} from '@hconnect/uikit/src/common'
import {MenuItem, TextField} from '@mui/material'
import moment from 'moment-timezone'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {
  convertToDate,
  DateTimeFragment,
  adjustEnLocale
} from '../../../../../../shared/selectors/time'

export interface DatetimeSelectProps {
  type: DateTimeFragment
  value: string
  restrictionDatetime: string
  timezoneId: string
  handleDateChanges: (newValue: string, type: DateTimeFragment) => void
  menuOptions: (restrictedDatetime?: string) => string[]
  isDisabled?: boolean
  'data-test-id'?: string
}

export const DatetimeSelect: React.FC<DatetimeSelectProps> = ({
  type,
  value,
  restrictionDatetime,
  timezoneId,
  handleDateChanges,
  menuOptions,
  isDisabled = false,
  'data-test-id': dataTestId = 'datetime_select'
}) => {
  const {
    t,
    i18n: {language}
  } = useTranslation()

  return (
    <TextField
      {...spreadDataTestId(dataTestId)}
      id={`schedule-edit-${type}`}
      label={t(`planning.${type}`)}
      select
      fullWidth
      onClick={(event) => event.stopPropagation()}
      value={convertToDate(moment.utc(value).tz(timezoneId).startOf('day'))}
      onChange={(e) => handleDateChanges(e.target.value, type)}
      variant="outlined"
      disabled={isDisabled}
    >
      {menuOptions(restrictionDatetime).map((option) => (
        <MenuItem key={option} value={option} {...spreadDataTestId(option)}>
          {dateFormatter(option, adjustEnLocale(language))}
        </MenuItem>
      ))}
    </TextField>
  )
}
