import {MaterialType} from '@hconnect/common/types'
import {dataTestId} from '@hconnect/uikit'
import {Box} from '@mui/material'
import React from 'react'

import {useStoredMaterialIdsFromHistoryQuery} from '../../../../shared/hooks/api'
import {useMaterialsStockLevelsQuery} from '../../../../shared/hooks/api/kpi/useMaterialsStockLevelsQuery'
import {usePlanningChartScheduleData} from '../PlanningChartScheduleDataProvider'
import {usePlanningChartStartEnd} from '../PlanningChartStartEndProvider'

import {StoredMaterial} from './StoredMaterial'

interface SilosGridParams {
  materialType?: MaterialType
  onSelectMaterial: (materialId: number) => void
}

const _SilosGridSection: React.FC<SilosGridParams> = ({materialType, onSelectMaterial}) => {
  const {startOfPlan, endOfPlan, endOfChart} = usePlanningChartStartEnd()
  const {isOptimized} = usePlanningChartScheduleData()
  const {data: stockLevelsData} = useMaterialsStockLevelsQuery({
    pointAtTime: endOfChart,
    planRange: [startOfPlan, endOfPlan],
    useOptimizedSchedule: isOptimized
  })

  const {data: storedMaterialIds} = useStoredMaterialIdsFromHistoryQuery({
    materialType,
    timeFrame: [startOfPlan, endOfPlan]
  })

  if (!storedMaterialIds) {
    throw new Error('BUG: storedMaterialIds should be loaded fore rendering this component')
  }

  return (
    <Box
      sx={{
        mt: 2,
        display: 'grid',
        rowGap: 4,
        columnGap: 6,
        gridTemplateColumns: 'repeat(auto-fit, minmax(400px, 1fr))'
      }}
      {...dataTestId('silo_section_content')}
    >
      {storedMaterialIds.map((materialId) => (
        <StoredMaterial
          onClick={() => onSelectMaterial(materialId)}
          sx={{cursor: 'pointer'}}
          key={materialId}
          materialId={materialId}
          stock={stockLevelsData?.[materialId] ?? 0}
        />
      ))}
    </Box>
  )
}
export const SilosGridSection = React.memo(_SilosGridSection)
SilosGridSection.displayName = 'SilosGridSection'
