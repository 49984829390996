import {dataTestId} from '@hconnect/uikit'
import {Button} from '@mui/material'
import {useTranslation} from 'react-i18next'

import {fetchHcemQuery} from '../../../../../shared/api/queries'
import {useScheduleCostAvoidanceDialog} from '../../../../../shared/components/providers/ScheduleCostAvoidanceDialogProvider'
import {useUrlParam} from '../../../../../shared/hooks/useUrlParam'
import {usePlanningChartScheduleData} from '../../PlanningChartScheduleDataProvider'

export const CalculateCostAvoidanceButton = () => {
  const {t} = useTranslation()
  const plantCode = useUrlParam('plantCode')
  const {schedule} = usePlanningChartScheduleData()
  if (!schedule) {
    throw new Error('BUG: Schedule should be loaded to use this component')
  }
  const {scheduleId} = schedule
  const {setIsDialogOpen} = useScheduleCostAvoidanceDialog()

  const handleCalculateCostAvoidance = () => {
    void fetchHcemQuery('scheduleCostAvoidance', {plantCode, scheduleId})
    setIsDialogOpen(true)
  }

  return (
    <Button
      variant="contained"
      id="calculate-cost-avoidance"
      onClick={handleCalculateCostAvoidance}
      {...dataTestId('calculate_cost_avoidance_button')}
    >
      {t('planning.calculateCostAvoidance')}
    </Button>
  )
}
