import {dataTestId} from '@hconnect/uikit'
import {Card, CardTitle} from '@hconnect/uikit/src/lib2'
import {Box, useTheme} from '@mui/material'
import {useTranslation} from 'react-i18next'

import {useSelectedTimeframe} from '../../../shared/components/providers/SelectedTimeframeProvider'
import {StaticStockDevelopmentChart} from '../../../shared/components/stockDevelopmentChart/StaticStockDevelopmentChart'
import {StockDevelopmentChartSkeleton} from '../../../shared/components/stockDevelopmentChart/StockDevelopmentChartSkeleton'
import {useLatestQuery, useMaterialStorageDataQuery} from '../../../shared/hooks/api'
import {useStockDevelopmentQuery} from '../../../shared/hooks/api/kpi/useStockDevelopmentQuery'
import {useIncomingMaterialsQuery} from '../../../shared/hooks/api/materialOrders/useIncomingMaterialsQuery'
import {usePlantConfig} from '../../../shared/hooks/usePlantConfigData'
import {useUrlParam} from '../../../shared/hooks/useUrlParam'
import {getIncomingMaterialHourly} from '../../../shared/selectors/materialOrders'
import {getListOfDays} from '../../../shared/selectors/time'

import {StockDevelopmentChartLegend} from './StockDevelopmentChartLegend'

export const DetailedStockDevelopmentCard = () => {
  const {t} = useTranslation()
  const materialIdParam = useUrlParam('materialId')
  const materialId = Number(materialIdParam)
  const {selectedTimeframe} = useSelectedTimeframe()

  const {spacing} = useTheme()
  const {data: latest} = useLatestQuery()
  const {timezone_id: timezoneId} = usePlantConfig()

  const {data: stockDevelopment, isLoading: isStockDevelopmentLoading} = useStockDevelopmentQuery({
    materialId,
    timeframe: selectedTimeframe
  })
  const {data: incomingMaterial, isLoading: isIncomingMaterialLoading} = useIncomingMaterialsQuery({
    materialId,
    timeframe: selectedTimeframe
  })
  const {data: storageData} = useMaterialStorageDataQuery({
    materialId
  })

  const listOfDays = selectedTimeframe
    ? getListOfDays({start: selectedTimeframe[0], end: selectedTimeframe[1]})
    : undefined

  const shouldShowChartSkeleton =
    !selectedTimeframe ||
    !listOfDays ||
    !latest ||
    isStockDevelopmentLoading ||
    isIncomingMaterialLoading ||
    !stockDevelopment
  const chartHeight = spacing(50)
  const incomingMaterialHourly = incomingMaterial && getIncomingMaterialHourly(incomingMaterial)

  return (
    <Card
      headerContent={
        <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
          <CardTitle variant="h3" sx={{mb: 0}}>
            {t('stock.stockDevelopment')}
          </CardTitle>
          <StockDevelopmentChartLegend />
        </Box>
      }
      {...dataTestId('stock_development_card')}
    >
      {shouldShowChartSkeleton ? (
        <StockDevelopmentChartSkeleton height={chartHeight} />
      ) : (
        <StaticStockDevelopmentChart
          timezoneId={timezoneId}
          listOfDays={listOfDays}
          stockData={stockDevelopment}
          incomingMaterialHourly={incomingMaterialHourly}
          minTargetLevels={
            storageData && {
              week: storageData.minTargetLevel,
              weekend: storageData.minTargetLevelWeekend
            }
          }
          deadStockLevel={storageData?.deadStockLevel}
          storageCapacity={storageData?.storageCapacity}
          height={parseInt(chartHeight)}
        />
      )}
    </Card>
  )
}
