import {Moment} from 'moment-timezone'

import {useUrlParam} from '../../useUrlParam'
import {usePlannerQuery} from '../usePlannerQuery'

interface OptimizedScheduleQueryParams {
  isDisabled?: boolean
  startOfPlan: Moment
  endOfPlan: Moment
}

export const useOptimizedScheduleQuery = ({
  startOfPlan,
  endOfPlan,
  isDisabled = false
}: OptimizedScheduleQueryParams) => {
  const plantCode = useUrlParam('plantCode')

  return usePlannerQuery(
    'optimizedSchedule',
    [{plantCode, start: startOfPlan.toISOString(), end: endOfPlan.toISOString()}],
    {enabled: !isDisabled}
  )
}
