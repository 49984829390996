import {useMutation} from '@tanstack/react-query'
import {AxiosError} from 'axios'
import {Moment} from 'moment-timezone'

import {mutations} from '../../../api/mutations'
import {getHcemQueryData, invalidateHcemQuery, setHcemQueryData} from '../../../api/queryHelpers'
import {hcemQueryClient} from '../../../components/providers/QueryProvider'
import {trackPlannerEvent} from '../../../helpers/trackPlannerEvents'
import {ScheduleItem, Schedule, MutationError} from '../../../interfaces/api'
import {usePlannerErrorHandler} from '../../usePlannerErrorHandler'
import {useUrlParam} from '../../useUrlParam'

type ScheduleContext = {previousState: Schedule}
interface DeleteScheduleItemParams {
  startOfPlan: Moment
  endOfPlan: Moment
}

export const useDeleteScheduleItem = ({startOfPlan, endOfPlan}: DeleteScheduleItemParams) => {
  const onError = usePlannerErrorHandler()
  const plantCode = useUrlParam('plantCode')
  const queryVariables = {plantCode, start: startOfPlan.toISOString(), end: endOfPlan.toISOString()}

  const {mutate} = useMutation<
    Schedule,
    AxiosError<MutationError<ScheduleItem>>,
    Parameters<typeof mutations.deleteScheduleItem>[0]
  >({
    mutationFn: mutations.deleteScheduleItem,
    onMutate: async (variables) => {
      await hcemQueryClient.cancelQueries({queryKey: ['schedule']})
      const previousState = getHcemQueryData('schedule', [queryVariables])
      const id = variables.scheduleItem.id
      if (previousState) {
        const updatedSchedule = {...previousState, schedules: {...previousState.schedules}}
        delete updatedSchedule.schedules[id]
        setHcemQueryData('schedule', [queryVariables], updatedSchedule)
      }
      trackPlannerEvent('onDeleteScheduleItem', {plantId: variables.plantCode})
      return {previousState} as ScheduleContext
    },
    onSuccess: (schedule) => {
      setHcemQueryData('schedule', [queryVariables], schedule)
    },
    onError: () => {
      invalidateHcemQuery('schedule')
    },
    onSettled: (data, error, {plantCode}) => {
      invalidateHcemQuery('electricity', {plantCode})
      invalidateHcemQuery('costAvoidanceForRange')
      invalidateHcemQuery('stockDevelopment')
    }
  })

  return (scheduleItem: ScheduleItem) => {
    const queryKey = ['schedule', queryVariables]
    const schedule = hcemQueryClient.getQueryData<Schedule>(queryKey)
    if (!schedule) return
    mutate({plantCode, scheduleId: schedule.scheduleId, scheduleItem}, {onError})
  }
}
