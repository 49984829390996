import React from 'react'
import {Outlet} from 'react-router-dom'

import {prefetchHcemQuery} from '../../shared/api/queries'
import {DefaultShell} from '../../shared/components/DefaultShell'
import {GuidedToursShell} from '../../shared/components/GuidedToursShell'
import {PlannerHeader} from '../../shared/components/PlannerHeader'
import {PlannerToursProvider} from '../../shared/components/providers/PlannerToursProvider'
import {PlannerPageSkeleton} from '../../shared/components/skeletons/PlannerPageSkeleton'
import {usePermissionsQuery, usePlantConfigQuery} from '../../shared/hooks/api'
import {useUrlParam} from '../../shared/hooks/useUrlParam'

export const PagePlant: React.FC = () => {
  const plantCode = useUrlParam('plantCode')
  const {data: plantConfig} = usePlantConfigQuery()
  const {data: permissions} = usePermissionsQuery()
  prefetchHcemQuery('materials', plantCode)
  prefetchHcemQuery('materialsRecipes', {plantCode})

  return plantConfig && permissions ? (
    <PlannerToursProvider>
      <GuidedToursShell>
        <Outlet />
      </GuidedToursShell>
    </PlannerToursProvider>
  ) : (
    <DefaultShell HeaderContent={<PlannerHeader />}>
      <PlannerPageSkeleton />
    </DefaultShell>
  )
}
