import moment, {Moment} from 'moment-timezone'
import React, {useContext, useMemo} from 'react'

import {useOptimizedScheduleQuery} from '../../../shared/hooks/api/schedules/useOptimizedScheduleQuery'
import {useScheduleQuery} from '../../../shared/hooks/api/schedules/useScheduleQuery'
import {useAssetHistoryDataByScheduleItemId} from '../../../shared/hooks/api/useAssetHistoryDataByScheduleItemId'
import {Schedule} from '../../../shared/interfaces/api'
import {AssetWithOperationMode} from '../../../shared/selectors/assets'
import {MomentRange} from '../../../shared/selectors/time'

import {usePlanningChartStartEnd} from './PlanningChartStartEndProvider'

interface PlanningChartScheduleDataState {
  schedule: Schedule | undefined
  scheduleLastUpdatedAt: Moment | undefined
  isOptimized: boolean
  assetDataByScheduleItemId?: Record<string, AssetWithOperationMode>
  isScheduleFetching: boolean
}

const PlanningChartScheduleDataContext = React.createContext<
  PlanningChartScheduleDataState | undefined
>(undefined)

export const usePlanningChartScheduleData = () => {
  const context = useContext(PlanningChartScheduleDataContext)
  if (!context) {
    throw new Error(
      'Cannot use PlanningChartScheduleDataContext outside of a PlanningChartScheduleDataProvider'
    )
  }
  return context
}

interface PlanningChartScheduleDataProviderProps {
  isOptimized?: boolean
  children: React.ReactNode
}

export const PlanningChartScheduleDataProvider = ({
  isOptimized = false,
  children
}: PlanningChartScheduleDataProviderProps) => {
  const {startOfPlan, endOfPlan} = usePlanningChartStartEnd()
  const planRange: MomentRange = [startOfPlan, endOfPlan]
  const {
    data: manualSchedule,
    isFetching: isManualScheduleFetching,
    dataUpdatedAt: manualScheduleUpdatedAt
  } = useScheduleQuery({isDisabled: isOptimized, range: planRange})
  const {
    data: optimizedSchedule,
    isFetching: isOptimizedScheduleFetching,
    dataUpdatedAt: optimizedScheduleUpdatedAt
  } = useOptimizedScheduleQuery({isDisabled: !isOptimized, startOfPlan, endOfPlan})

  const schedule = isOptimized ? optimizedSchedule : manualSchedule

  const assetDataByScheduleItemId = useAssetHistoryDataByScheduleItemId({
    timeFrame: planRange,
    schedule
  })

  const scheduleLastUpdatedAt = schedule
    ? moment.utc(isOptimized ? optimizedScheduleUpdatedAt : manualScheduleUpdatedAt)
    : undefined
  const isScheduleFetching = isOptimized ? isOptimizedScheduleFetching : isManualScheduleFetching

  const memoizedState: PlanningChartScheduleDataState = useMemo(
    () => ({
      schedule,
      scheduleLastUpdatedAt,
      isOptimized,
      isScheduleFetching,
      assetDataByScheduleItemId
    }),
    [schedule, scheduleLastUpdatedAt, isOptimized, isScheduleFetching, assetDataByScheduleItemId]
  )

  return (
    <PlanningChartScheduleDataContext.Provider value={memoizedState}>
      {children}
    </PlanningChartScheduleDataContext.Provider>
  )
}
