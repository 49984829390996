import {Stack} from '@mui/material'
import React from 'react'

import {SelectedScheduleItemProvider} from '../../shared/components/providers/SelectedScheduleItemProvider'
import {useSelectedTimeframe} from '../../shared/components/providers/SelectedTimeframeProvider'
import {
  useHistoryAssetsQuery,
  useHistoryStoragesByIdQuery,
  useLatestQuery,
  usePeakLoadWindowsQuery,
  useMaterialsRecipes
} from '../../shared/hooks/api'
import {MomentRange} from '../../shared/selectors/time'

import {DailyProductionPlanningActions} from './dailyPlanning/DailyProductionPlanningActions'
import {PlanningChartElectricityDataProvider} from './dailyPlanning/PlanningChartElectricityDataProvider'
import {PlanningChartScheduleDataProvider} from './dailyPlanning/PlanningChartScheduleDataProvider'
import {PlanningChartStartEndProvider} from './dailyPlanning/PlanningChartStartEndProvider'
import {PageProductionPlanningHeader} from './PageProductionPlanningHeader'
import {ProductionPlanningChartContainer} from './ProductionPlanningChartContainer'

const MIN_RANGE_HOURS = 24
const MAX_RANGE_HOURS = 24 * 7

interface LayoutDailyPlanningProps {
  updateSliderDefaultRange: (range: number) => void
  sliderDefaultRange: number
  boundary: MomentRange
}

export const LayoutDailyPlanning: React.FC<LayoutDailyPlanningProps> = ({
  updateSliderDefaultRange,
  sliderDefaultRange,
  boundary
}) => {
  const {selectedTimeframe} = useSelectedTimeframe()
  if (!selectedTimeframe) {
    throw new Error('selectedTimeframe is not defined')
  }

  // prefetching queries
  useLatestQuery()
  usePeakLoadWindowsQuery(selectedTimeframe)

  const [startOfPlan, endOfPlan] = selectedTimeframe

  // prefetching required data
  useHistoryStoragesByIdQuery({timeFrame: [startOfPlan, endOfPlan]})
  useHistoryAssetsQuery({
    timeFrame: [startOfPlan, endOfPlan],
    sorted: true
  })
  useMaterialsRecipes()

  return (
    <PlanningChartStartEndProvider
      sliderDefaultRange={sliderDefaultRange}
      sliderMinMaxRange={[MIN_RANGE_HOURS, MAX_RANGE_HOURS]}
      boundary={boundary}
      updateSliderDefaultRange={updateSliderDefaultRange}
      startOfPlan={startOfPlan}
      endOfPlan={endOfPlan}
    >
      <SelectedScheduleItemProvider>
        <PlanningChartScheduleDataProvider>
          <PlanningChartElectricityDataProvider>
            <Stack spacing={2}>
              <PageProductionPlanningHeader actions={<DailyProductionPlanningActions />} />
              <ProductionPlanningChartContainer />
            </Stack>
          </PlanningChartElectricityDataProvider>
        </PlanningChartScheduleDataProvider>
      </SelectedScheduleItemProvider>
    </PlanningChartStartEndProvider>
  )
}
