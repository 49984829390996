import {HistoryMaterialStorage} from '@hconnect/common/types'
import {dataTestId} from '@hconnect/uikit'
import {Stack, Typography} from '@mui/material'
import {Moment} from 'moment-timezone'
import {FC} from 'react'
import {useTranslation} from 'react-i18next'

import {
  useHistoryStoragesByIdQuery,
  usePeakLoadWindowsQuery,
  useElectricityQuery
} from '../../../../../../shared/hooks/api'
import {useUpdateScheduleItem} from '../../../../../../shared/hooks/api/schedules/useUpdateScheduleItem'
import {ScheduleItem} from '../../../../../../shared/interfaces/api'
import {usePlanningChartScheduleData} from '../../../PlanningChartScheduleDataProvider'

import {SelectStorageButton} from './SelectStorageButton'

interface SelectStoragesProps {
  availableStorages: (Pick<HistoryMaterialStorage, 'id' | 'name'> & {isSelected: boolean})[]
  scheduleItem: ScheduleItem
  startOfPlan: Moment
  endOfPlan: Moment
  isReadOnly: boolean
}

export const SelectStorages: FC<SelectStoragesProps> = ({
  availableStorages,
  scheduleItem,
  startOfPlan,
  endOfPlan,
  isReadOnly
}) => {
  const {t} = useTranslation()
  const {data: storagesById} = useHistoryStoragesByIdQuery({
    timeFrame: [startOfPlan, endOfPlan]
  })
  if (!storagesById) {
    throw new Error('BUG: storagesById should be loaded before rendering this component')
  }
  const {
    selectedSilos: {ids: allSelectedSiloIds}
  } = scheduleItem
  const {data: peakLoadWindows} = usePeakLoadWindowsQuery([startOfPlan, endOfPlan])
  if (!peakLoadWindows) {
    throw new Error('BUG: peak load windows should be loaded before using SelectStorages')
  }
  const {assetDataByScheduleItemId} = usePlanningChartScheduleData()
  const {data: electricity} = useElectricityQuery([startOfPlan, endOfPlan])
  const {updateScheduleItem, isUpdateScheduleItemLoading} = useUpdateScheduleItem({
    startOfPlan,
    endOfPlan,
    electricity,
    peakLoadWindows,
    assetDataByScheduleItemId
  })

  // BE stores selected storages in scheduler without any storage knowledge, we need to check it ourselves
  const isSelectedStorageIdValid = (storageId: number) =>
    availableStorages.map(({id}) => id).includes(storageId)

  const selectedSilosIds = allSelectedSiloIds.filter(isSelectedStorageIdValid)

  const getNewStoragesIdsSorted = (id: number) => {
    return selectedSilosIds.includes(id)
      ? selectedSilosIds.filter((selectedId) => selectedId !== id)
      : [...selectedSilosIds, id].sort((a, b) =>
          storagesById[a].name.localeCompare(storagesById[b].name)
        )
  }

  const handleSelectStorage = (storageId: number) => {
    const updatedIds = getNewStoragesIdsSorted(storageId)
    updateScheduleItem({
      ...scheduleItem,
      selectedSilos: {...scheduleItem.selectedSilos, ids: updatedIds}
    })
  }
  const isSelectStorageButtonDisabled = isReadOnly || isUpdateScheduleItemLoading

  return (
    <Stack spacing={2} {...dataTestId('unordered_storages_selector')}>
      <Typography variant="body2">{t('planning.deselectSilosInfo')}</Typography>
      <Stack direction="row" flexWrap="wrap" gap={1.5}>
        {availableStorages.map((storage) => (
          <SelectStorageButton
            key={storage.id}
            storage={storage}
            isSelected={storage.isSelected}
            isDisabled={isSelectStorageButtonDisabled}
            handleSelectStorage={handleSelectStorage}
          />
        ))}
      </Stack>
    </Stack>
  )
}
