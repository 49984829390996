import {
  convertDateHourRangeToNumberRange,
  convertNumberRangeToDateHourRange
} from '@hconnect/uikit/src/lib2/components/rangeSliders/DayRangeSlider/dayRangeSlider.utils'
import {debounce} from 'lodash'
import {Moment} from 'moment-timezone'
import React, {useContext, useMemo, useState, useEffect} from 'react'
import {useLocation} from 'react-router'

import {calculateRangeWithinBoundary} from '../../../shared/helpers/utils'
import {useChartStartEndFromURL} from '../../../shared/hooks/useChartStartEndFromURL'
import {getLeftAlignedHourListFromRange} from '../../../shared/selectors'
import {MomentRange, NumberRange} from '../../../shared/selectors/time'

const DEFAULT_STEPS_PER_HOUR = 1 as const

interface PlanningChartStartEndState {
  startOfPlan: Moment
  endOfPlan: Moment
  startOfChart: Moment
  endOfChart: Moment
  boundary: MomentRange
  hoursList: Moment[]
  visibleHoursList: Moment[]
  hoursRange: NumberRange
  setHoursRange: React.Dispatch<React.SetStateAction<NumberRange>>
  stepsPerHour: 1
  totalSteps: number
}

interface PlanningChartStartEndProps {
  // slider range in hours
  sliderDefaultRange: number
  sliderMinMaxRange: NumberRange
  updateSliderDefaultRange: (range: number) => void
  startOfPlan: Moment
  endOfPlan: Moment
  boundary: MomentRange
  children: React.ReactNode
  stepsPerHour?: number
}

const PlanningChartStartEndContext = React.createContext<PlanningChartStartEndState | undefined>(
  undefined
)

export const usePlanningChartStartEnd = () => {
  const context = useContext(PlanningChartStartEndContext)
  if (!context) {
    throw new Error(
      'Cannot use PlanningChartStartEndContext outside of a PlanningChartStartEndProvider'
    )
  }
  return context
}

export const PlanningChartStartEndProvider = ({
  sliderDefaultRange,
  sliderMinMaxRange: [sliderMinRange, sliderMaxRange],
  updateSliderDefaultRange,
  startOfPlan,
  endOfPlan,
  boundary,
  children
}: PlanningChartStartEndProps) => {
  const {pathname} = useLocation()

  const hoursList: Moment[] = useMemo(
    () => getLeftAlignedHourListFromRange(startOfPlan, endOfPlan),
    [startOfPlan, endOfPlan]
  )

  const totalSteps = hoursList.length * DEFAULT_STEPS_PER_HOUR

  const [{start, end}, updateChartStartEnd] = useChartStartEndFromURL({
    startOfPlan,
    endOfPlan,
    minMaxRangeHours: [sliderMinRange, sliderMaxRange]
  })
  // TODO consider to remove hour index range, use dates instead
  const initialRange = convertDateHourRangeToNumberRange([start, end], startOfPlan)
  const [hoursRange, setHoursRange] = useState<[number, number]>(initialRange)

  const debouncedSetHourRangeToUrl = useMemo(
    () => debounce(([start, end]: MomentRange) => updateChartStartEnd([start, end]), 500),
    [updateChartStartEnd]
  )

  // we need to cancel debounced setting params if url changes
  useEffect(() => {
    return () => {
      debouncedSetHourRangeToUrl.cancel()
    }
  }, [pathname, debouncedSetHourRangeToUrl])

  useEffect(() => {
    const [start, end] = convertNumberRangeToDateHourRange(
      [hoursRange[0], hoursRange[1]],
      startOfPlan
    )
    debouncedSetHourRangeToUrl([start, end])
  }, [hoursRange, startOfPlan, debouncedSetHourRangeToUrl])

  const hoursInRange = hoursRange[1] - hoursRange[0]

  useEffect(() => {
    if (hoursInRange !== sliderDefaultRange) {
      updateSliderDefaultRange(hoursInRange)
    }
  }, [hoursInRange, sliderDefaultRange, updateSliderDefaultRange])

  // if the hours list is shorter than the range, we need to adjust the selected range
  useEffect(() => {
    if (hoursList.length < hoursRange[1]) {
      setHoursRange(calculateRangeWithinBoundary(hoursRange, [0, totalSteps]))
    }
  }, [hoursList, totalSteps, hoursRange])

  const visibleHoursList = useMemo(
    () => hoursList.slice(hoursRange[0], hoursRange[1]),
    [hoursRange, hoursList]
  )

  const memoizedState = useMemo(
    () => ({
      startOfPlan,
      endOfPlan,
      startOfChart: startOfPlan.clone().add(hoursRange[0], 'hours'),
      endOfChart: startOfPlan.clone().add(hoursRange[1], 'hours'),
      boundary,
      hoursRange,
      hoursList,
      visibleHoursList,
      setHoursRange,
      stepsPerHour: DEFAULT_STEPS_PER_HOUR,
      totalSteps
    }),
    [hoursRange, startOfPlan, endOfPlan, hoursList, visibleHoursList, totalSteps, boundary]
  )

  return (
    <PlanningChartStartEndContext.Provider value={memoizedState}>
      {children}
    </PlanningChartStartEndContext.Provider>
  )
}
