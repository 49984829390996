import React from 'react'

import {ElectricityResponse, Electricity} from '../../../interfaces/api'
import {mapLoadCurvesResToElectricityData} from '../../../selectors/electricity'
import {MomentRange} from '../../../selectors/time'
import {usePlantConfig} from '../../usePlantConfigData'
import {useUrlParam} from '../../useUrlParam'
import {usePlannerQuery} from '../usePlannerQuery'

const selector = (data: ElectricityResponse, timezoneId: string): Electricity =>
  mapLoadCurvesResToElectricityData(data, timezoneId)

export const useElectricityQuery = ([start, end]: MomentRange) => {
  const plantCode = useUrlParam('plantCode')
  const {timezone_id: timezoneId} = usePlantConfig()
  return usePlannerQuery(
    'electricity',
    [{plantCode, start: start.toISOString(), end: end.toISOString()}],
    {
      select: React.useCallback(
        (data: ElectricityResponse) => selector(data, timezoneId),
        [timezoneId]
      )
    }
  )
}
