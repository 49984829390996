import {useNotification} from '@hconnect/uikit/src/common'
import {MutateOptions, useMutation} from '@tanstack/react-query'
import {AxiosError} from 'axios'
import {useCallback} from 'react'
import {useErrorHandler} from 'react-error-boundary'

import {mutations} from '../../../api/mutations'
import {notifyIfErrorMessage} from '../../../common'
import {hcemQueryClient} from '../../../components/providers/QueryProvider'
import {CommentsCategory} from '../../../enums'
import {trackPlannerEvent} from '../../../helpers/trackPlannerEvents'
import {ErrorResponse, Comment} from '../../../interfaces/api'

export const useAddScheduleComment = () => {
  const {notify} = useNotification()
  const raiseError = useErrorHandler()
  const {mutate, isLoading: isAddCommentLoading} = useMutation<
    Comment,
    AxiosError<ErrorResponse>,
    Parameters<typeof mutations.addComment>[0]
  >({
    mutationFn: mutations.addComment,
    mutationKey: ['addScheduleComment'],
    onError: (error) => {
      notifyIfErrorMessage(error.response?.data.detail, raiseError, notify)
    },
    onSettled: (data, error, variables) => {
      trackPlannerEvent('onCommentAddToScheduleItem', {plantId: variables.plantCode})
      void hcemQueryClient.invalidateQueries({
        queryKey: ['comments', {commentsCategory: CommentsCategory.AssetOperationTimes}]
      })
    }
  })

  const addScheduleComment = useCallback(
    (
      params: Omit<Parameters<typeof mutations.addComment>[0], 'commentsCategory'>,
      options?: MutateOptions<
        Comment,
        AxiosError<ErrorResponse>,
        Parameters<typeof mutations.addComment>[0]
      >
    ) => mutate({...params, commentsCategory: CommentsCategory.AssetOperationTimes}, options),
    [mutate]
  )

  return {
    addScheduleComment,
    isAddCommentLoading
  }
}
