import {dataTestId} from '@hconnect/uikit'
import {HPButton} from '@hconnect/uikit/src/lib2'
import {Add} from '@mui/icons-material'
import {Stack} from '@mui/material'
import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'

import {PastFutureTimeframeSelect} from '../../../shared/components/formComponents/PastFutureTimeframeSelect'
import {useSelectedMaterialOrder} from '../../../shared/components/providers/SelectedMaterialOrderProvider'
import {useSelectedTimeframe} from '../../../shared/components/providers/SelectedTimeframeProvider'
import {usePermission} from '../../../shared/hooks/permissions/usePermission'
import {usePlanRange} from '../../../shared/hooks/usePlanRange'
import {usePlantConfig} from '../../../shared/hooks/usePlantConfigData'

const TIMEFRAME_DURATIONS_DAYS = [1, 3, 5, 9, 15]

export const MaterialOrdersTitleActions: React.FC = () => {
  const {t} = useTranslation()
  const {created_at: createdAt, timezone_id: timezoneId} = usePlantConfig()
  const {selectedTimeframe, setSelectedTimeframe} = useSelectedTimeframe()
  const {setSelectedMaterialOrder} = useSelectedMaterialOrder()
  const [startOfPlan] = usePlanRange({createdAt, timezoneId})

  const canChangeMaterialOrders = usePermission('CHANGE_MATERIAL_ORDERS')

  useEffect(() => {
    if (!selectedTimeframe) {
      const start = startOfPlan.clone().subtract(1, 'day').startOf('day')
      const end = start.clone().add(10, 'days')
      setSelectedTimeframe([start, end])
    }
  }, [selectedTimeframe, setSelectedTimeframe, startOfPlan])

  if (!selectedTimeframe) return null

  return (
    <Stack direction="row" spacing={1.5}>
      <PastFutureTimeframeSelect
        pastDurationDays={TIMEFRAME_DURATIONS_DAYS}
        futureDurationDays={TIMEFRAME_DURATIONS_DAYS}
        startOfToday={startOfPlan.clone().startOf('day')}
        timeframe={selectedTimeframe}
        onTimeframeChange={setSelectedTimeframe}
      />
      <HPButton
        startIcon={<Add />}
        variant="contained"
        color="primary"
        backgroundMode="medium"
        onClick={() => setSelectedMaterialOrder({id: undefined})}
        disabled={!canChangeMaterialOrders}
        {...dataTestId('create_order_button')}
      >
        {t('stock.createOrder')}
      </HPButton>
    </Stack>
  )
}
