import {Skeleton, Stack} from '@mui/material'
import {Moment} from 'moment-timezone'
import {useNavigate} from 'react-router-dom'

import {PLANT_ROUTES, getUrl} from '../../routing'
import {trackPlannerEvent} from '../../shared/helpers/trackPlannerEvents'
import {useRejectOptimizedSchedule} from '../../shared/hooks/api/schedules/useRejectOptimizedSchedule'
import {useSelectOptimizedSchedule} from '../../shared/hooks/api/schedules/useSelectOptimizedSchedule'
import {useUrlParam} from '../../shared/hooks/useUrlParam'
import {getScheduleKPIsDiff} from '../../shared/scheduleKPIs'

import {AcceptRejectOptimizedPlanButtons} from './AcceptRejectOptimizedPlanButtons'
import {HighlightedKPIs} from './HighlightedKPIs'
import {useOptimizedAndManualScheduleKPIs} from './shared/useOptimizedAndManualScheduleKPIs'

interface OptimizerKPIsProps {
  startOfPlan: Moment
  endOfPlan: Moment
  planType: 'optimized' | 'manual'
}

export const OptimizerDetailsKPIs = ({startOfPlan, endOfPlan, planType}: OptimizerKPIsProps) => {
  const navigate = useNavigate()
  const plantCode = useUrlParam('plantCode')

  const scheduleKPIs = useOptimizedAndManualScheduleKPIs(startOfPlan, endOfPlan)

  const {mutate: rejectSchedule} = useRejectOptimizedSchedule({startOfPlan, endOfPlan})
  const {mutate: selectSchedule} = useSelectOptimizedSchedule({startOfPlan, endOfPlan})

  const handleRejectOptimizedPlan = () => {
    trackPlannerEvent('onAcceptLastSavedPlanFromDetailView', {
      plantId: plantCode
    })
    trackPlannerEvent('onRejectOptimizedPlan', {plantId: plantCode})
    rejectSchedule(
      {plantCode},
      {onSuccess: () => navigate(getUrl(PLANT_ROUTES.PRODUCTION_PLANNING.path, {plantCode}))}
    )
  }

  const handleSelectOptimizedPlan = () => {
    trackPlannerEvent('onAcceptOptimizedPlanFromDetailView', {
      plantId: plantCode
    })
    selectSchedule(
      {plantCode},
      {onSuccess: () => navigate(getUrl(PLANT_ROUTES.PRODUCTION_PLANNING.path, {plantCode}))}
    )
  }

  if (!scheduleKPIs) {
    return <Skeleton variant="rectangular" height="100%" />
  }

  const {
    totalCosts: totalCostsOptimized,
    totalCementTons: totalCementTonsOptimized,
    costsPerTon: costsPerTonOptimized,
    costsPerMWh: costsPerMWhOptimized
  } = scheduleKPIs.optimized

  const {
    totalCosts: totalCostsManual,
    totalCementTons: totalCementTonsManual,
    costsPerTon: costsPerTonManual,
    costsPerMWh: costsPerMWhManual
  } = scheduleKPIs.manual

  const {tonsDiff, costsDiff, costsPerTonDiff, costsPerMWhDiff} = getScheduleKPIsDiff({
    type: planType,
    values: {
      tonsManual: totalCementTonsManual,
      tonsOptimized: totalCementTonsOptimized,
      costsManual: totalCostsManual,
      costsOptimized: totalCostsOptimized,
      costsPerTonManual: costsPerTonManual,
      costsPerTonOptimized: costsPerTonOptimized,
      costsPerMWhManual: costsPerMWhManual,
      costsPerMWhOptimized: costsPerMWhOptimized
    }
  })

  const isOptimized = planType === 'optimized'

  return (
    <Stack spacing={2}>
      <HighlightedKPIs
        producedTons={isOptimized ? totalCementTonsOptimized : totalCementTonsManual}
        producedTonsDifference={tonsDiff}
        totalPrice={isOptimized ? totalCostsOptimized : totalCostsManual}
        totalPriceDifference={costsDiff}
        pricePerTon={isOptimized ? costsPerTonOptimized : costsPerTonManual}
        pricePerTonDifference={costsPerTonDiff}
        pricePerMWh={isOptimized ? costsPerMWhOptimized : costsPerMWhManual}
        pricePerMWhDifference={costsPerMWhDiff}
        testId={`${planType}_plan`}
      />
      <AcceptRejectOptimizedPlanButtons
        handleRejectOptimizedPlan={handleRejectOptimizedPlan}
        handleSelectOptimizedPlan={handleSelectOptimizedPlan}
      />
    </Stack>
  )
}
