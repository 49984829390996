import {dataTestId} from '@hconnect/uikit'
import {dateFormatter, timeFormatter} from '@hconnect/uikit/src/common'
import {InfoOutlined} from '@mui/icons-material'
import {Box, Button, Tooltip, Typography} from '@mui/material'
import {Moment} from 'moment-timezone'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {getSubmitTimeFrame} from '../../../../shared/helpers/utils'
import {useElectricityQuery} from '../../../../shared/hooks/api'
import {usePlantConfig} from '../../../../shared/hooks/usePlantConfigData'
import {useUrlParam} from '../../../../shared/hooks/useUrlParam'
import {adjustEnLocale, getPlantUtcOffset} from '../../../../shared/selectors/time'

interface EnergyProviderButtonProps {
  startOfPlan: Moment
  endOfPlan: Moment
}

export const EnergyProviderButton: React.FC<EnergyProviderButtonProps> = ({
  startOfPlan,
  endOfPlan
}) => {
  const {
    t,
    i18n: {exists: translationExists, language}
  } = useTranslation()
  const {timezone_id: timezoneId} = usePlantConfig()
  const plantCode = useUrlParam('plantCode')
  const {data: electricity} = useElectricityQuery([startOfPlan, endOfPlan])

  const [submitStart, submitEnd] = getSubmitTimeFrame(electricity?.upForPurchasing)

  const powerPurchaseInfoTranslationKey = `planning.powerPurchaseInfo.${plantCode}`

  const formatDay = (date: Moment | undefined) =>
    date && dateFormatter(date, adjustEnLocale(language), getPlantUtcOffset(timezoneId))
  const formatTime = (date: Moment | undefined) =>
    date && timeFormatter(date, language, getPlantUtcOffset(timezoneId))
  const startDate = formatDay(submitStart)
  const startTime = formatTime(submitStart)
  const endDate = formatDay(submitEnd)
  const endTime = formatTime(submitEnd)

  return (
    <Box sx={{display: 'flex'}}>
      <Tooltip
        placement="top"
        title={
          <>
            <Typography variant="body1">
              {t('planning.kpiToolTip', {startDate, startTime, endDate, endTime})}
            </Typography>
            {translationExists(powerPurchaseInfoTranslationKey) && (
              <Typography variant="body1" sx={{mt: 2}}>
                {t(powerPurchaseInfoTranslationKey)}
              </Typography>
            )}
          </>
        }
      >
        <Button
          startIcon={<InfoOutlined />}
          variant="text"
          sx={{color: 'primary.light'}}
          {...dataTestId('energy_provider_button')}
        >
          {t('planning.energyProvider')}
        </Button>
      </Tooltip>
    </Box>
  )
}
