import {roundTo15MinIntervalStart} from '@hconnect/common/utils'
import MockAdapter from 'axios-mock-adapter'
import moment from 'moment-timezone'
import {v4 as uuidv4} from 'uuid'

import {ScheduleStatus} from '../../shared/enums'
import {getCurrentTime} from '../../shared/hooks/useCurrentTime'
import {
  OperationModeResponse,
  Schedule,
  ScheduleItem,
  ScheduleItemDict
} from '../../shared/interfaces/api'
import {getScheduleItemPowerConsumptionQuaterlyDict} from '../../shared/selectors/electricity'
import {mockStore, TEST_PLANT_CODE} from '../mockStore'

import {sleepResponse, numberRegEx, saveScenario, uuidRegEx} from './utils'

export const enableSchedulerEndpoints = (mock: MockAdapter) => {
  // GET current Schedule (C#)
  mock.onGet('/schedules/current').reply((config) => {
    const {start, end} = config.params as {
      start: string
      end: string
    }
    const {
      burglengenfeld: {schedule}
    } = mockStore.scenario()

    const returnSchedule: Schedule = {
      ...schedule,
      schedules: Object.fromEntries(
        Object.entries(schedule.schedules).filter((entry) => {
          const scheduleItem = entry[1]
          return (
            moment.utc(scheduleItem.end).isAfter(moment.utc(start)) &&
            moment.utc(scheduleItem.start).isBefore(moment.utc(end))
          )
        })
      )
    }
    return sleepResponse([200, returnSchedule])
  })
  // GET optimized Schedule (C#)
  // will be deprecated in the future
  mock.onGet('/schedules/latest').reply((config) => {
    const {start, end, status} = config.params as {
      start: string
      end: string
      status?: ScheduleStatus
    }
    const {
      burglengenfeld: {schedule, optimized_schedule: optimizedSchedule}
    } = mockStore.scenario()
    const retrievedSchedule = status === ScheduleStatus.PENDING ? optimizedSchedule : schedule

    const returnSchedule: Schedule = {
      ...retrievedSchedule,
      schedules: Object.fromEntries(
        Object.entries(retrievedSchedule.schedules).filter((entry) => {
          const scheduleItem = entry[1]
          return (
            moment.utc(scheduleItem.end).isAfter(moment.utc(start)) &&
            moment.utc(scheduleItem.start).isBefore(moment.utc(end))
          )
        })
      )
    }

    return sleepResponse([200, returnSchedule])
  })

  // Select Optimized Schedule (C#)
  mock.onPut(new RegExp('/schedules/select')).reply(() => {
    const scenario = mockStore.scenario()
    scenario.burglengenfeld.schedule = scenario.burglengenfeld.optimized_schedule
    scenario.burglengenfeld.schedule.isOptimizedScheduleAvailable = false
    saveScenario(scenario)
    return sleepResponse([200, scenario.burglengenfeld.optimized_schedule])
  })

  // Reject Optimized Schedule (C#)
  mock.onPut(new RegExp('/schedules/reject')).reply(() => {
    const scenario = mockStore.scenario()
    scenario.burglengenfeld.schedule.isOptimizedScheduleAvailable = false
    saveScenario(scenario)
    return sleepResponse([200, {}])
  })

  // POST Schedule Item (C#)
  mock.onPost(new RegExp(`/schedules/${numberRegEx}/items`)).reply((config) => {
    const scheduleItem = JSON.parse(config.data as string) as Omit<ScheduleItem, 'id'>
    const scenario = mockStore.scenario()
    const {assetsById, electricity} = scenario.burglengenfeld
    const id = uuidv4()
    const newScheduleItem = {...scheduleItem, id}
    const updatedSchedules: ScheduleItemDict = {
      ...scenario.burglengenfeld.schedule.schedules,
      [id]: newScheduleItem
    }

    const activeOperationMode = assetsById[newScheduleItem.assetId].operationModes.find(
      (operationMode) => operationMode.id === newScheduleItem.assetOperationModeId
    )!
    const powerConsumptionForUpdatedItemDict = getScheduleItemPowerConsumptionQuaterlyDict(
      newScheduleItem,
      activeOperationMode
    )

    const adjustedPowerConsumption = electricity.planned.map((energyConsumption) => {
      const newItemPower = powerConsumptionForUpdatedItemDict[energyConsumption.datetime] || 0
      return {...energyConsumption, power: energyConsumption.power + newItemPower}
    })
    scenario.burglengenfeld.electricity.planned = adjustedPowerConsumption
    scenario.burglengenfeld.schedule.schedules = updatedSchedules
    saveScenario(scenario)
    return sleepResponse([200, scenario.burglengenfeld.schedule])
  })

  // PUT Schedule Item (C#)
  mock.onPut(new RegExp(`/schedules/${numberRegEx}/items/${uuidRegEx}`)).reply((config) => {
    const scheduleItem = JSON.parse(config.data as string) as ScheduleItem
    const scenario = mockStore.scenario()
    const {
      burglengenfeld: {
        plantConfig: {timezone_id: timezoneId},
        schedule: prevSchedule,
        assetHistoryDataByScheduleItemId,
        electricity
      }
    } = scenario

    const prevScheduleItem = prevSchedule.schedules[scheduleItem.id]
    const currentTimeRounded = roundTo15MinIntervalStart(getCurrentTime(timezoneId))

    const powerConsumptionForPrevItemDict = getScheduleItemPowerConsumptionQuaterlyDict(
      prevScheduleItem,
      assetHistoryDataByScheduleItemId[prevScheduleItem.id].operationMode
    )

    const isScheduleItemPartiallyInThePast = currentTimeRounded.isBetween(
      moment.utc(scheduleItem.start),
      moment.utc(scheduleItem.end)
    )

    const shouldSplitItem =
      (prevScheduleItem.assetOperationModeId !== scheduleItem.assetOperationModeId ||
        prevScheduleItem.isTransitionTime !== scheduleItem.isTransitionTime) &&
      isScheduleItemPartiallyInThePast

    const activeOperationMode = assetHistoryDataByScheduleItemId[
      scheduleItem.id
    ].asset.operationModes.find(
      (operationMode) => operationMode.id === scheduleItem.assetOperationModeId
    ) as OperationModeResponse

    if (shouldSplitItem) {
      const adjustedItem = {...prevScheduleItem, end: currentTimeRounded.toISOString()}
      const newItem = {...scheduleItem, start: currentTimeRounded.toISOString(), id: uuidv4()}

      // adjusting power consumption for the new item
      const powerConsumptionForUpdatedItemDict = getScheduleItemPowerConsumptionQuaterlyDict(
        newItem,
        activeOperationMode
      )

      const adjustedPowerConsumption = electricity.planned.map((energyConsumption) => {
        const prevItemPower = powerConsumptionForPrevItemDict[energyConsumption.datetime] || 0
        const updatedItemPower = powerConsumptionForUpdatedItemDict[energyConsumption.datetime] || 0
        const diff = updatedItemPower - prevItemPower
        return {...energyConsumption, power: energyConsumption.power + diff}
      })

      scenario.burglengenfeld.electricity.planned = adjustedPowerConsumption

      scenario.burglengenfeld.schedule.schedules = {
        ...scenario.burglengenfeld.schedule.schedules,
        [scheduleItem.id]: adjustedItem,
        [newItem.id]: newItem
      }
      scenario.burglengenfeld.schedule.splitItemId = newItem.id
    } else {
      // adjusting power consumption for the new item
      const powerConsumptionForUpdatedItemDict = getScheduleItemPowerConsumptionQuaterlyDict(
        scheduleItem,
        activeOperationMode
      )

      const adjustedPowerConsumption = electricity.planned.map((energyConsumption) => {
        const prevItemPower = powerConsumptionForPrevItemDict[energyConsumption.datetime] || 0
        const updatedItemPower = powerConsumptionForUpdatedItemDict[energyConsumption.datetime] || 0
        const diff = updatedItemPower - prevItemPower
        return {...energyConsumption, power: energyConsumption.power + diff}
      })

      scenario.burglengenfeld.electricity.planned = adjustedPowerConsumption
      scenario.burglengenfeld.schedule.schedules = {
        ...scenario.burglengenfeld.schedule.schedules,
        [scheduleItem.id]: scheduleItem
      }
    }
    saveScenario(scenario)
    return sleepResponse([200, scenario.burglengenfeld.schedule])
  })

  // DELETE Schedule Item (C#)
  mock.onDelete(new RegExp(`/schedules/${numberRegEx}/items/${uuidRegEx}`)).reply((config) => {
    const url: string = config.url as string
    const id: string = url.substring(url.lastIndexOf('/') + 1)
    const scenario = mockStore.scenario()
    const prevSchedule = scenario.burglengenfeld.schedule
    const updatedSchedule = {...prevSchedule, schedules: {...prevSchedule.schedules}}
    delete updatedSchedule.schedules[id]
    scenario.burglengenfeld.schedule = updatedSchedule
    saveScenario(scenario)
    return sleepResponse([200, updatedSchedule])
  })

  // GET date range cost avoidance data
  mock
    .onGet(
      new RegExp(`/plants/${TEST_PLANT_CODE}/schedules/${numberRegEx}/kpi/cost-avoidance-for-range`)
    )
    .reply(() => {
      const {
        burglengenfeld: {costAvoidanceForRange}
      } = mockStore.scenario()

      return sleepResponse([200, costAvoidanceForRange])
    })

  // GET Schedule cost avoidance data
  mock
    .onGet(new RegExp(`/plants/${TEST_PLANT_CODE}/schedules/${numberRegEx}/kpi/cost-avoidance`))
    .reply((config) => {
      const scheduleId = (config.url as string).split('/')[4]
      const {
        burglengenfeld: {schedulesCostAvoidance: scheduleCostAvoidance}
      } = mockStore.scenario()

      return sleepResponse([200, scheduleCostAvoidance[scheduleId]])
    })

  // GET daily cost avoidance data
  mock
    .onGet(new RegExp(`^/plants/${TEST_PLANT_CODE}/schedules/kpi/cost-avoidance$`))
    .reply((config) => {
      const {from, to} = config.params as {from: string; to: string}
      const {
        burglengenfeld: {costAvoidanceDaily}
      } = mockStore.scenario()

      const filteredCostAvoidanceDaily = costAvoidanceDaily.filter(({date}) => {
        const dateUTC = moment.utc(date)
        return dateUTC.isSameOrAfter(moment.utc(from)) && dateUTC.isSameOrBefore(moment.utc(to))
      })

      return sleepResponse([200, filteredCostAvoidanceDaily])
    })

  // GET monthly cost avoidance data
  mock
    .onGet(new RegExp(`/plants/${TEST_PLANT_CODE}/schedules/kpi/cost-avoidance/monthly`))
    .reply((config) => {
      const {from, to} = config.params as {from: string; to: string}
      const {
        burglengenfeld: {costAvoidanceMontly}
      } = mockStore.scenario()
      const filteredCostAvoidanceMontly = costAvoidanceMontly.filter(({date}) => {
        const dateUTC = moment.utc(date)
        return dateUTC.isSameOrAfter(moment.utc(from)) && dateUTC.isSameOrBefore(moment.utc(to))
      })

      return sleepResponse([200, filteredCostAvoidanceMontly])
    })

  // GET stock development
  mock
    .onGet(new RegExp(`/plants/${TEST_PLANT_CODE}/schedules/kpi/stock-development`))
    .reply((config) => {
      const {from, to, materialIds, useOptimizedSchedule} = config.params as {
        from: string
        to: string
        materialIds: string
        useOptimizedSchedule?: boolean
      }
      const parsedMaterialIds = materialIds.split(',')
      const {
        burglengenfeld: {stockDevelopment, optimizedStockDevelopment}
      } = mockStore.scenario()
      const selectedStockDevelopment = useOptimizedSchedule
        ? optimizedStockDevelopment
        : stockDevelopment
      const filteredStockDevelopmentByMaterialIds = Object.fromEntries(
        Object.entries(selectedStockDevelopment).filter(([materialId]) =>
          parsedMaterialIds.includes(materialId)
        )
      )
      const filteredStockDevelopmentByRange = Object.fromEntries(
        Object.entries(filteredStockDevelopmentByMaterialIds).map(
          ([materialId, stockDevelopmentEntry]) => [
            materialId,
            {
              actual: stockDevelopmentEntry.actual.filter((datetimeValue) =>
                moment.utc(datetimeValue.datetime).isBetween(from, to, 'second', '[]')
              ),
              forecast: stockDevelopmentEntry.forecast.filter((datetimeValue) =>
                moment.utc(datetimeValue.datetime).isBetween(from, to, 'second', '[]')
              )
            }
          ]
        )
      )
      return sleepResponse([200, filteredStockDevelopmentByRange])
    })

  return mock
}
