import {dataTestId} from '@hconnect/uikit'
import {ModeCommentOutlined} from '@mui/icons-material'
import {Box, Button} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {usePlanningComments} from '../../../../../shared/hooks/api/comments'
import {usePlanningChartStartEnd} from '../../PlanningChartStartEndProvider'

interface CommentSidebarButtonProps {
  toggleCommentSidebar: () => void
}

export const _CommentSidebarButton: React.FC<CommentSidebarButtonProps> = ({
  toggleCommentSidebar
}) => {
  const {startOfPlan, endOfPlan} = usePlanningChartStartEnd()
  const {comments} = usePlanningComments({startOfPlan, endOfPlan})
  const {t} = useTranslation()

  return (
    <Box>
      <Button
        variant="text"
        sx={{color: 'primary.light'}}
        startIcon={<ModeCommentOutlined />}
        {...dataTestId('comments_sidebar_button')}
        onClick={toggleCommentSidebar}
      >
        {`${t('planning.numberOfComments', {count: comments.length}).toUpperCase()}`}
      </Button>
    </Box>
  )
}

export const CommentSidebarButton = React.memo(_CommentSidebarButton)
