import {datetimeFormatter, formatTons} from '@hconnect/uikit'
import {CardTitle} from '@hconnect/uikit/src/lib2'
import {Typography} from '@mui/material'
import {Stack} from '@mui/system'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {DatetimeValue} from '../../interfaces/common'

interface StockDevelopmentTooltipContentProps {
  datetimeValue: DatetimeValue
}

export const StockDevelopmentTooltipContent: React.FC<StockDevelopmentTooltipContentProps> = ({
  datetimeValue: {value, datetime}
}) => {
  const {
    t,
    i18n: {language}
  } = useTranslation()
  return (
    <>
      <CardTitle>{datetimeFormatter(datetime, language)}</CardTitle>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="body1">{t('common.stock')}</Typography>
        <Typography variant="subtitle1">{formatTons(value, language)}</Typography>
      </Stack>
    </>
  )
}
