import {trackEvent} from '@hconnect/common/logging/Analytics'

import {PlannerTrackedFeatures} from '../enums'

type BasePlannerEvent =
  | {
      name: 'appActive'
    }
  | {
      name: 'onSubmitPlanClick'
      data: {userId: string}
    }
  | {
      name: 'onEditScheduleItem'
    }
  | {
      name: 'onDeleteScheduleItem'
    }
  | {
      name: 'onAddScheduleItem'
    }
  | {
      name: 'onCommentAddToScheduleItem'
    }
  | {
      name: 'onCommentEditToScheduleItem'
    }
  | {
      name: 'onCommentDeleteToScheduleItem'
    }
  | {
      name: 'onCommentAddToAsset'
    }
  | {
      name: 'onCommentDeleteToAsset'
    }
  | {
      name: 'onCommentEditToAsset'
    }
  | {
      name: 'onPageView'
      data: {pageName: string}
    }
  | {
      name: 'onViewOptimizedPlan'
    }
  | {
      name: 'onAcceptOptimizedPlan'
    }
  | {
      name: 'onRejectOptimizedPlan'
    }
  | {
      name: 'onViewDetailsOptimizedPlan'
    }
  | {
      name: 'onViewDetailsLastSavedPlan'
    }
  | {
      name: 'onSwitchToViewLastSavedPlan'
    }
  | {
      name: 'onSwitchToViewOptimizedPlan'
    }
  | {
      name: 'onClickAwayFromViewOptimizedPlan'
    }
  | {
      name: 'onAcceptLastSavedPlanFromDetailView'
    }
  | {
      name: 'onAcceptOptimizedPlanFromDetailView'
    }
  | {
      name: 'onExportProductionPlan'
    }
  | {
      name: 'userEntersMaterialPlan'
      data: {
        userId: string
        plantName: string
        country: string
        feature: PlannerTrackedFeatures.RawMaterialsPlanning
      }
    }

interface CommonPlannerData {
  data: {
    plantId: string
  }
}

type PlannerEvent = BasePlannerEvent & CommonPlannerData

export const trackPlannerEvent = <T extends PlannerEvent['name']>(
  eventName: T,
  data: Extract<PlannerEvent, {name: T}>['data']
) => trackEvent(eventName, {product: 'planner', date: new Date().toISOString(), ...data})
