import React, {useContext, useMemo} from 'react'

import {useElectricityQuery, usePeakLoadWindowsQuery} from '../../../shared/hooks/api'
import {PowerConsumption} from '../../../shared/interfaces/api'
import {PeakLoadWindow, filterPeakLoadWindows} from '../../../shared/selectors'
import {getPeakLoadWindows} from '../../../shared/selectors/electricity'

import {usePlanningChartStartEnd} from './PlanningChartStartEndProvider'

interface PlanningChartElectricityDataState {
  peakLoadWindows: PeakLoadWindow[]
  powerConsumptionQuarterly?: PowerConsumption[]
}

const PlanningChartElectricityDataContext = React.createContext<
  PlanningChartElectricityDataState | undefined
>(undefined)

export const usePlanningChartElectricityData = () => {
  const context = useContext(PlanningChartElectricityDataContext)
  if (!context) {
    throw new Error(
      'Cannot use PlanningChartElectricityDataContext outside of a PlanningChartElectricityDataProvider'
    )
  }
  return context
}

interface PlanningChartScheduleDataProviderProps {
  isOptimized?: boolean
  children: React.ReactNode
}

export const PlanningChartElectricityDataProvider = ({
  children
}: PlanningChartScheduleDataProviderProps) => {
  const {startOfPlan, endOfPlan} = usePlanningChartStartEnd()
  const {data: electricity} = useElectricityQuery([startOfPlan, endOfPlan])
  const {data: peakLoadWindows} = usePeakLoadWindowsQuery([startOfPlan, endOfPlan])
  const powerConsumptionQuarterly = electricity?.planned

  const peakLoadWindowsInfo = useMemo(
    () =>
      powerConsumptionQuarterly && peakLoadWindows
        ? getPeakLoadWindows({
            peakLoadWindows,
            powerConsumption: powerConsumptionQuarterly
          })
        : undefined,
    [peakLoadWindows, powerConsumptionQuarterly]
  )

  const filteredPeakLoadWindows = useMemo(
    () => filterPeakLoadWindows(peakLoadWindowsInfo, startOfPlan, endOfPlan),
    [peakLoadWindowsInfo, startOfPlan, endOfPlan]
  )

  const memoizedState: PlanningChartElectricityDataState = useMemo(
    () => ({
      peakLoadWindows: filteredPeakLoadWindows,
      powerConsumptionQuarterly
    }),
    [filteredPeakLoadWindows, powerConsumptionQuarterly]
  )

  return (
    <PlanningChartElectricityDataContext.Provider value={memoizedState}>
      {children}
    </PlanningChartElectricityDataContext.Provider>
  )
}
