import {Box, CardProps as MUICardProps} from '@mui/material'
import {Theme} from '@mui/material/styles'
import {SxProps} from '@mui/system'
import React, {UIEventHandler} from 'react'

import {mergeSx} from '../../utils'
import {CardTitle} from '../typography'

import {CardBox} from './CardBox'

export interface CardProps extends Partial<Pick<MUICardProps, 'onClick' | 'sx'>> {
  headerContent?: string | React.ReactElement
  footerContent?: React.ReactNode
  cardTitleSx?: SxProps<Theme>
  bodySx?: SxProps<Theme>
  onScroll?: UIEventHandler<HTMLDivElement>
  'data-test-id'?: string
  children: React.ReactNode
}

export const Card: React.FC<CardProps> = ({
  headerContent,
  children: content,
  footerContent,
  onClick,
  'data-test-id': testId = 'card',
  sx,
  cardTitleSx,
  bodySx,
  onScroll
}) => {
  const isHeaderContentString = typeof headerContent === 'string'
  return (
    <CardBox
      sx={onClick ? {cursor: 'pointer', ...sx} : sx}
      onClick={onClick}
      onScroll={onScroll}
      data-test-id={testId}
      tabIndex={-1}
    >
      {isHeaderContentString ? (
        <CardTitle sx={{mb: 0, ...cardTitleSx}}>{headerContent}</CardTitle>
      ) : (
        headerContent
      )}
      <Box
        sx={mergeSx(
          {
            ...(headerContent && {pt: {xs: 2, md: 3}}),
            ...(footerContent && {pb: {xs: 2, md: 3}})
          },
          bodySx
        )}
      >
        {content}
      </Box>
      {footerContent}
    </CardBox>
  )
}
