import {useParams} from 'react-router'

import {UrlParams} from '../../../../routing'
import {usePlannerQuery} from '../usePlannerQuery'

export const useLatestQuery = (plantCode?: string) => {
  const {plantCode: urlPlantCode} = useParams<UrlParams>()

  return usePlannerQuery('latest', [plantCode ?? urlPlantCode], {
    enabled: Boolean(plantCode || urlPlantCode)
  })
}
