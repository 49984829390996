import {roundTo15MinIntervalStart} from '@hconnect/common/utils'
import {dataTestId} from '@hconnect/uikit'
import moment from 'moment-timezone'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {PlannerSwitch} from '../../../../../../shared/components/formComponents/PlannerSwitch'
import {getGanttChartEndOfPlanning} from '../../../../../../shared/components/ganttChart/ganttChartEndOfPlanning'
import {useCurrentTimeRounded} from '../../../../../../shared/hooks/useCurrentTimeRounded'
import {usePlantConfig} from '../../../../../../shared/hooks/usePlantConfigData'
import {ScheduleItem} from '../../../../../../shared/interfaces/api'

interface ScheduleItemTransitionTimeSwitchProps {
  scheduleItem: ScheduleItem
  handleTransitionTimeChange: (isTransitionTime: boolean, shouldSplit: boolean) => void
}
export const ScheduleItemTransitionTimeSwitch: React.FC<ScheduleItemTransitionTimeSwitchProps> = ({
  scheduleItem,
  handleTransitionTimeChange
}) => {
  const {t} = useTranslation()
  const {timezone_id: timezoneId} = usePlantConfig()
  const nowRounded = useCurrentTimeRounded({timezoneId, roundingFn: roundTo15MinIntervalStart})
  // TODO: remove endOfPlanning with HCP-72218
  const endOfPlanning = getGanttChartEndOfPlanning(nowRounded)
  const isTransitionTimeChangeDisabled =
    moment.utc(scheduleItem.end).isBefore(nowRounded) ||
    moment.utc(scheduleItem.end).isAfter(endOfPlanning)

  const shouldDisplaySplitWarning = moment.utc(scheduleItem.start).isBefore(nowRounded)

  const onTransitionTimeChange = (isChecked: boolean) =>
    handleTransitionTimeChange(isChecked, shouldDisplaySplitWarning)

  return (
    <PlannerSwitch
      label={t('planning.transitionTime')}
      value={scheduleItem.isTransitionTime}
      onChange={onTransitionTimeChange}
      disabled={isTransitionTimeChangeDisabled}
      {...dataTestId('transition_time_toggle')}
    />
  )
}
