import MockAdapter from 'axios-mock-adapter'
import moment from 'moment-timezone'

import {mockStore} from '../mockStore'

import {sleepResponse, numberRegEx} from './utils'

export const enableMaterialStorageEndpoints = (mock: MockAdapter) => {
  // GET material storage (C#)
  mock.onGet(new RegExp(`^/plants/${numberRegEx}/material-storage$`)).reply(() => {
    const {
      burglengenfeld: {currentMaterialStorages}
    } = mockStore.scenario()

    return sleepResponse([200, currentMaterialStorages])
  })

  // GET material storage history (C#)
  mock.onGet(new RegExp(`^/plants/${numberRegEx}/material-storage/history$`)).reply((config) => {
    const {from, to} = config.params as {from: string; to: string}
    const {
      burglengenfeld: {materialStorageHistory}
    } = mockStore.scenario()

    const selectedMaterialStorageHistory = Object.fromEntries(
      Object.entries(materialStorageHistory)
        .map(
          ([storageId, storageSnapshots]) =>
            [
              storageId,
              storageSnapshots.filter((storage) =>
                moment.utc(storage.updatedOn ?? storage.createdOn).isBefore(to)
              )
            ] as [string, typeof storageSnapshots]
        )
        .map(([storageId, storageSnapshots]) => {
          const filterByFromDate = storageSnapshots.filter((storage) =>
            moment.utc(storage.updatedOn ?? storage.createdOn).isAfter(from)
          )
          return [
            storageId,
            filterByFromDate.length
              ? filterByFromDate
              : [storageSnapshots[storageSnapshots.length - 1]]
          ] as [string, typeof filterByFromDate]
        })
    )

    return sleepResponse([200, selectedMaterialStorageHistory])
  })

  return mock
}
