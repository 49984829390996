import {useNotification} from '@hconnect/uikit/src/common'
import {useMutation} from '@tanstack/react-query'
import {AxiosError} from 'axios'
import {useErrorHandler} from 'react-error-boundary'
import {useTranslation} from 'react-i18next'

import {mutations} from '../../../api/mutations'
import {notifyIfErrorMessage} from '../../../common'
import {hcemQueryClient} from '../../../components/providers/QueryProvider'
import {CommentsCategory} from '../../../enums'
import {trackPlannerEvent} from '../../../helpers/trackPlannerEvents'
import {ErrorResponse} from '../../../interfaces/api'

export const useDeleteComment = () => {
  const {t} = useTranslation()
  const {notify} = useNotification()
  const raiseError = useErrorHandler()
  return useMutation<
    void,
    AxiosError<ErrorResponse>,
    Parameters<typeof mutations.deleteComment>[0]
  >({
    mutationFn: mutations.deleteComment,
    onError: (error) => {
      notifyIfErrorMessage(error.response?.data.detail, raiseError, notify)
    },
    onSuccess: () => {
      notify('success', t('success.deletedComment'))
    },
    onSettled: (data, error, variables) => {
      const eventName =
        variables.commentsCategory === CommentsCategory.AssetOperationTimes
          ? 'onCommentDeleteToScheduleItem'
          : 'onCommentDeleteToAsset'
      trackPlannerEvent(eventName, {plantId: variables.plantCode})
      void hcemQueryClient.invalidateQueries({
        queryKey: ['comments', {commentsCategory: variables.commentsCategory}]
      })
    }
  })
}
