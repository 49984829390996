import {Stack, Collapse} from '@mui/material'
import {Moment} from 'moment-timezone'
import React, {useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import {CollapsibleCard} from '../../../shared/components/CollapsibleCard'
import {PlannerFeature, PlannerLSSettingsKeys} from '../../../shared/enums'
import {dataTestId} from '@hconnect/uikit'
import {useElectricityQuery} from '../../../shared/hooks/api'
import {useElectricityPricesDataQuery} from '../../../shared/hooks/api/dataScience/useElectricityPricesDataQuery'
import {usePersistingSettingState} from '../../../shared/hooks/usePersistingSettingState'
import {usePlannerFeature} from '../../../shared/hooks/usePlannerFeature'
import {getHourlyPowerDiff} from '../../../shared/selectors/electricity'
import {ECChartLegend} from '../../page-planning/dailyPlanning/electricity-chart/ECChartLegend'
import {ElectricityChart} from '../../page-planning/dailyPlanning/electricity-chart/ElectricityChart'
import {usePlanningChartElectricityData} from '../../page-planning/dailyPlanning/PlanningChartElectricityDataProvider'

interface OptimizerElectricityChartCardProps {
  startOfPlan: Moment
  endOfPlan: Moment
  hoursList: Moment[]
  cellWidth: number
  cellsDisplayed: number
  labelColumnWidth: number
}

const _OptimizerElectricityChartCard: React.FC<OptimizerElectricityChartCardProps> = ({
  startOfPlan,
  endOfPlan,
  hoursList,
  cellWidth,
  labelColumnWidth,
  cellsDisplayed
}) => {
  const {t} = useTranslation()
  const chartWidth = cellWidth * cellsDisplayed
  const {data: electricity} = useElectricityQuery([startOfPlan, endOfPlan])
  const {data: electricityPrices} = useElectricityPricesDataQuery([startOfPlan, endOfPlan])
  const actualPrices = electricityPrices?.actual ?? []

  const canPurchaseElectricity = usePlannerFeature(PlannerFeature.ElectricityPurchasing)

  const actualPricesEnd = actualPrices.length
    ? actualPrices[actualPrices.length - 1].datetime
    : undefined

  const [energyPurchased, energyUpForPurchase, totalEnergyPurchased] = useMemo(
    () =>
      canPurchaseElectricity
        ? [electricity?.purchased, electricity?.upForPurchasing, electricity?.submitted]
        : [],
    [electricity, canPurchaseElectricity]
  )

  const {powerConsumptionQuarterly, peakLoadWindows} = usePlanningChartElectricityData()

  const [minValue, maxValue] = useMemo(() => {
    const powerValues = powerConsumptionQuarterly?.map((item) => item.power)
    return powerValues ? [Math.min(...powerValues), Math.max(...powerValues)] : [0, 1]
  }, [powerConsumptionQuarterly])

  const hourlyDiff =
    powerConsumptionQuarterly && totalEnergyPurchased
      ? getHourlyPowerDiff({
          hours: hoursList,
          purchasedQuarterlyPower: totalEnergyPurchased,
          plannedQuarterlyPower: powerConsumptionQuarterly
        })
      : undefined

  const [{isElectricityChartOpen}, setIsElectricityChartOpen] = usePersistingSettingState(
    PlannerLSSettingsKeys.PlanningElectricityChartSettings,
    {isElectricityChartOpen: true}
  )

  const toggleIsElectricityChartOpen = () =>
    setIsElectricityChartOpen({isElectricityChartOpen: !isElectricityChartOpen})

  const electricityChartHeight = maxValue > 2 ? 200 : 100

  return (
    <CollapsibleCard
      isCollapsed={!isElectricityChartOpen}
      onToggleCollapsed={toggleIsElectricityChartOpen}
      title={t('planning.mwPlanned')}
      headerContent={
        <Collapse in={isElectricityChartOpen}>
          <Stack direction="row" spacing={2} sx={{justifyContent: 'flex-end'}}>
            <ECChartLegend showPurchasedInfo={canPurchaseElectricity} />
          </Stack>
        </Collapse>
      }
      {...dataTestId('electricity_chart_card')}
    >
      <Stack sx={{positin: 'relative'}} spacing={2}>
        <ElectricityChart
          hoursList={hoursList}
          cellWidth={cellWidth}
          yAxisWidth={labelColumnWidth}
          visibleChartWidth={chartWidth}
          height={electricityChartHeight}
          powerQuarterly={powerConsumptionQuarterly}
          energyPurchased={energyPurchased}
          energyUpForPurchase={energyUpForPurchase}
          minValue={minValue}
          maxValue={maxValue}
          peakLoadWindows={peakLoadWindows}
          actualPricesEnd={actualPricesEnd}
          hourlyDiff={hourlyDiff}
          isMinimized={!isElectricityChartOpen}
        />
      </Stack>
    </CollapsibleCard>
  )
}

export const OptimizerElectricityChartCard = React.memo(_OptimizerElectricityChartCard)
