import {roundTo15MinIntervalStart} from '@hconnect/common/utils'
import {dataTestId} from '@hconnect/uikit'
import moment from 'moment-timezone'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {PlannerSwitch} from '../../../../../../shared/components/formComponents/PlannerSwitch'
import {getGanttChartEndOfPlanning} from '../../../../../../shared/components/ganttChart/ganttChartEndOfPlanning'
import {useCurrentTimeRounded} from '../../../../../../shared/hooks/useCurrentTimeRounded'
import {usePlantConfig} from '../../../../../../shared/hooks/usePlantConfigData'
import {ScheduleItem} from '../../../../../../shared/interfaces/api'

interface ScheduleItemShutdownAvailabilitySwitchProps {
  scheduleItem: ScheduleItem
  handleShutdownAvailabilityChange: (isShutdownAvailable: boolean) => void
}
export const ScheduleItemShutdownAvailabilitySwitch: React.FC<
  ScheduleItemShutdownAvailabilitySwitchProps
> = ({scheduleItem, handleShutdownAvailabilityChange}) => {
  const {t} = useTranslation()
  const {timezone_id: timezoneId} = usePlantConfig()
  const nowRounded = useCurrentTimeRounded({timezoneId, roundingFn: roundTo15MinIntervalStart})
  // TODO: remove endOfPlanning with HC72218
  const endOfPlanning = getGanttChartEndOfPlanning(nowRounded)
  const isDisabled =
    moment.utc(scheduleItem.end).isBefore(nowRounded) ||
    moment.utc(scheduleItem.end).isAfter(endOfPlanning)

  const onChange = (isChecked: boolean) => handleShutdownAvailabilityChange(isChecked)

  return (
    <PlannerSwitch
      label={t('planning.assetShutdownAvailability')}
      value={scheduleItem.isShutdownAvailable}
      onChange={onChange}
      disabled={isDisabled}
      {...dataTestId('asset_shutdown_availability')}
    />
  )
}
