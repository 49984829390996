import {useNotification} from '@hconnect/uikit'
import {useMutation} from '@tanstack/react-query'
import {AxiosError} from 'axios'
import {Moment} from 'moment-timezone'
import {useTranslation} from 'react-i18next'

import {mutations} from '../../../api/mutations'
import {
  getHcemQueryData,
  invalidateHcemQuery,
  removeHcemQuery,
  setHcemQueryData
} from '../../../api/queryHelpers'
import {hcemQueryClient} from '../../../components/providers/QueryProvider'
import {trackPlannerEvent} from '../../../helpers/trackPlannerEvents'
import {MutationError, Schedule} from '../../../interfaces/api'
import {usePlannerErrorHandler} from '../../usePlannerErrorHandler'

interface SelectOptimizedScheduleParams {
  startOfPlan: Moment
  endOfPlan: Moment
}

export const useSelectOptimizedSchedule = ({
  startOfPlan,
  endOfPlan
}: SelectOptimizedScheduleParams) => {
  const {notify} = useNotification()
  const {t} = useTranslation()
  const onError = usePlannerErrorHandler()
  return useMutation<
    Schedule,
    AxiosError<MutationError<string>>,
    Parameters<typeof mutations.selectSchedule>[0]
  >({
    mutationFn: mutations.selectSchedule,
    onMutate: async ({plantCode}) => {
      await hcemQueryClient.cancelQueries({queryKey: ['schedule']})
      const previousSchedule = getHcemQueryData('schedule', [
        {
          plantCode,
          start: startOfPlan.toISOString(),
          end: endOfPlan.toISOString()
        }
      ])
      if (previousSchedule) {
        const newSchedule: Schedule = {...previousSchedule, isOptimizedScheduleAvailable: false}
        setHcemQueryData(
          'schedule',
          [
            {
              plantCode,
              start: startOfPlan.toISOString(),
              end: endOfPlan.toISOString()
            }
          ],
          newSchedule
        )
      }
      return {previousSchedule}
    },
    onError: (err, {plantCode}) => {
      invalidateHcemQuery('schedule')
      invalidateHcemQuery('electricity', {plantCode})

      removeHcemQuery('stockDevelopment')
      invalidateHcemQuery('costAvoidanceForRange')
      invalidateHcemQuery('costAvoidanceDaily')
      invalidateHcemQuery('costAvoidanceMonthly')
      onError(err)
    },
    onSuccess: (data, {plantCode}) => {
      trackPlannerEvent('onAcceptOptimizedPlan', {plantId: plantCode})
      invalidateHcemQuery('schedule')
      invalidateHcemQuery('electricity', {plantCode})

      removeHcemQuery('stockDevelopment')
      invalidateHcemQuery('costAvoidanceForRange')
      invalidateHcemQuery('costAvoidanceDaily')
      invalidateHcemQuery('costAvoidanceMonthly')

      notify('success', t('optimizer.acceptOptimizedPlanNotification'))
    }
  })
}
