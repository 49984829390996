import {dataTestId} from '@hconnect/uikit'
import {Box, Skeleton, Slide} from '@mui/material'
import {Moment} from 'moment-timezone'
import React from 'react'

import {usePlanningComments} from '../../../../shared/hooks/api/comments'
import {HCEMPalette} from '../../../../shared/pallette'

import {CommentContent} from './CommentContent'

interface CommentsSidebarProps {
  width: number
  isOpen: boolean
  startOfPlan: Moment
  endOfPlan: Moment
  isReadOnly: boolean
}

export const CommentsSidebar: React.FC<CommentsSidebarProps> = ({
  width,
  isOpen,
  startOfPlan,
  endOfPlan,
  isReadOnly
}) => {
  const {comments, isLoading} = usePlanningComments({startOfPlan, endOfPlan})

  return (
    <Slide direction="left" in={isOpen} unmountOnExit>
      <Box
        sx={{
          py: 2,
          width,
          height: '100%',
          position: 'absolute',
          right: 0,
          top: 0,
          bottom: 0,
          zIndex: 3,
          backgroundColor: HCEMPalette.legacyBackgroundDefault,
          overflowY: 'auto',
          overflowX: 'hidden'
        }}
        {...dataTestId('comments_sidebar')}
      >
        {isLoading ? (
          <Skeleton
            variant="rectangular"
            height={'100%'}
            sx={{backgroundColor: HCEMPalette.oceanTeal}}
            {...dataTestId('comments_sidebar_skeleton')}
          />
        ) : (
          comments.map((comment) => (
            <CommentContent
              isReadOnly={isReadOnly}
              key={comment.commentId}
              comment={comment}
              startOfPlan={startOfPlan}
              endOfPlan={endOfPlan}
            />
          ))
        )}
      </Box>
    </Slide>
  )
}
