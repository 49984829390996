import moment, {Moment, MomentInput} from 'moment-timezone'

import {getGanttChartEndOfPlanning} from '../../../../../shared/components/ganttChart/ganttChartEndOfPlanning'
import type {TimeScaleFn} from '../../../../../shared/helpers/scale'
import type {Iso8601} from '../../../../../shared/interfaces/api'

const MINIMUM_SCHEDULE_ITEM_DURATION_MINUTES = 15

// convert pixel offset to date offset
const deltaToDatetime = (delta: number, now: MomentInput, cell15minWidth: number): Moment => {
  const retVal = moment.utc(now)
  const minutes = MINIMUM_SCHEDULE_ITEM_DURATION_MINUTES * Math.round(delta / cell15minWidth)
  retVal.add(minutes, 'minutes')
  return retVal
}

const getStartAndEndWithoutOverlap = (
  start: Moment,
  end: Moment,
  fixed?: 'start' | 'end'
): {start: Moment; end: Moment} => {
  if (!fixed) {
    return {
      start,
      end: start.isSame(end)
        ? end.clone().add(MINIMUM_SCHEDULE_ITEM_DURATION_MINUTES, 'minutes')
        : end
    }
  }

  const restriction = moment
    .utc(fixed === 'start' ? start : end)
    .add(MINIMUM_SCHEDULE_ITEM_DURATION_MINUTES * (fixed === 'start' ? 1 : -1), 'minutes')

  const adjustedStart = fixed === 'start' ? start : moment.min([start, restriction])
  const adjustedEnd = fixed === 'end' ? end : moment.max([end, restriction])

  return {
    start: adjustedStart,
    end: adjustedEnd
  }
}

/* function to return a valid date based on the current time, we cannot modify past!
   we also cannot modify too much into future ( TODO: remove after HCP-72218 )
 */
const getValidDate = (date: Moment, currentTime: Moment) => {
  const endOfPlanning = getGanttChartEndOfPlanning(currentTime)
  return moment.min([moment.max([date, currentTime]), endOfPlanning])
}

export interface GetScheduleItemPositionFnParams {
  cell15minWidth: number
  dateOnPlanFrame: (date: MomentInput) => Moment
  dateOnChartFrame: (date: MomentInput) => Moment
  currentTimeRounded: Moment
  xScale: TimeScaleFn
}

export interface GetScheduleItemPositionParams {
  start: Iso8601
  end: Iso8601
  deltaX?: number
  fixed?: 'start' | 'end'
}

export interface ScheduleItemPosition {
  itemWidth: number
  start: Moment
  end: Moment
  labelWidth: number
  labelOffset: number
}

export const getScheduleItemPositionFn =
  ({
    cell15minWidth,
    dateOnPlanFrame,
    dateOnChartFrame,
    currentTimeRounded,
    xScale
  }: GetScheduleItemPositionFnParams) =>
  ({start, end, deltaX, fixed}: GetScheduleItemPositionParams): ScheduleItemPosition => {
    const isStartFixedOrInitialDraw = fixed === 'start' || !deltaX
    const isEndFixedOrInitialDraw = fixed === 'end' || !deltaX

    // updating start and end of schedule item based on deltaX ignoring chart start and end limitations
    const updatedStart = isStartFixedOrInitialDraw
      ? moment.utc(start)
      : deltaToDatetime(deltaX, start, cell15minWidth)
    const updatedEnd = isEndFixedOrInitialDraw
      ? moment.utc(end)
      : deltaToDatetime(deltaX, end, cell15minWidth)

    const startOnPlanFrame = isStartFixedOrInitialDraw
      ? dateOnPlanFrame(updatedStart)
      : getValidDate(dateOnPlanFrame(updatedStart), currentTimeRounded)
    const endOnPlanFrame = isEndFixedOrInitialDraw
      ? dateOnPlanFrame(updatedEnd)
      : getValidDate(dateOnPlanFrame(updatedEnd), currentTimeRounded)

    const startOnChartFrame = isStartFixedOrInitialDraw
      ? dateOnChartFrame(updatedStart)
      : getValidDate(dateOnChartFrame(updatedStart), currentTimeRounded)
    const endOnChartFrame = isEndFixedOrInitialDraw
      ? dateOnChartFrame(updatedEnd)
      : getValidDate(dateOnChartFrame(updatedEnd), currentTimeRounded)

    const {start: itemStart, end: itemEnd} = getStartAndEndWithoutOverlap(
      startOnPlanFrame,
      endOnPlanFrame,
      fixed
    )
    const {start: labelStart, end: labelEnd} = getStartAndEndWithoutOverlap(
      startOnChartFrame,
      endOnChartFrame,
      fixed
    )

    const itemWidth = xScale(itemEnd) - xScale(itemStart)
    const visibleWidth = xScale(labelEnd) - xScale(labelStart)
    const labelWidth = visibleWidth > 0 ? visibleWidth : itemWidth
    const labelLeft = xScale(labelStart) - xScale(itemStart)
    const labelOffset = labelLeft > 0 && labelLeft < itemWidth ? labelLeft : 0

    return {
      itemWidth,
      start: itemStart,
      end: itemEnd,
      labelWidth,
      labelOffset
    }
  }
