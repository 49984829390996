import type {Material} from '@hconnect/common/types'
import {dataTestId} from '@hconnect/uikit'
import {Stack} from '@mui/material'
import {FC} from 'react'
import {useTranslation} from 'react-i18next'
import {Navigate, useLocation, useNavigate} from 'react-router-dom'

import {getUrl, PLANT_ROUTES, RouteName, ROUTES, RoutingState} from '../../../routing'
import {PlannerBackButton} from '../../../shared/components/buttons'
import {PlannerPageLayout} from '../../../shared/components/PlannerPageLayout'
import {SelectedMaterialOrderProvider} from '../../../shared/components/providers/SelectedMaterialOrderProvider'
import {SelectedTimeframeProvider} from '../../../shared/components/providers/SelectedTimeframeProvider'
import {PlannerPageSkeleton} from '../../../shared/components/skeletons/PlannerPageSkeleton'
import {pageDataTestId} from '../../../shared/formatutils'
import {useMaterialsById} from '../../../shared/hooks/api'
import {useUrlParam} from '../../../shared/hooks/useUrlParam'

import {CreateEditMaterialOrderDialog} from './createEditMaterialOrder'
import {DetailedStockDevelopmentCard} from './DetailedStockDevelopmentCard'
import {ManageOrdersSection} from './ManageOrdersSection'
import {MaterialOrdersTitleActions} from './MaterialOrdersTitleActions'

export const PageMaterialOrders: FC = () => {
  const {t} = useTranslation()
  const navigate = useNavigate()

  // TODO: refactor this using custom hook like useUrlParam
  const {state} = useLocation()
  const routingState = state as RoutingState

  const plantCode = useUrlParam('plantCode')
  const materialId = useUrlParam('materialId')
  const {data: materialsById} = useMaterialsById()

  if (!materialsById) {
    return <PlannerPageSkeleton />
  }
  const currentMaterial: Material | undefined = materialsById[materialId]
  if (!currentMaterial) {
    return <Navigate to={getUrl(ROUTES.BASE.NOT_FOUND.path, {})} replace />
  }

  if (currentMaterial.products.length === 0) {
    return (
      <Navigate
        to={getUrl(PLANT_ROUTES.STOCK.MATERIALS.path, {
          plantCode
        })}
        replace
      />
    )
  }

  const handleGoBack = () => {
    if (routingState?.materialsVisitedFrom === 'storage') {
      navigate(
        `${getUrl(PLANT_ROUTES.STOCK.SILOS.path, {plantCode})}?materialType=${currentMaterial.type}`
      )
    } else {
      // materialsVisitedFrom default is material
      navigate(
        `${getUrl(PLANT_ROUTES.STOCK.MATERIALS.path, {plantCode})}?materialType=${
          currentMaterial.type
        }`
      )
    }
  }

  return (
    <SelectedTimeframeProvider>
      <SelectedMaterialOrderProvider>
        <PlannerPageLayout
          isTourBannerEnabled={false}
          aboveTitle={
            <PlannerBackButton
              text={
                routingState?.materialsVisitedFrom === 'storage'
                  ? t('navItems.backToSilos')
                  : t('navItems.backToMaterials')
              }
              onGoBack={handleGoBack}
              {...dataTestId(
                routingState?.materialsVisitedFrom === 'storage'
                  ? 'back_to_silos_button'
                  : 'back_to_materials_button'
              )}
            />
          }
          headerActionContent={<MaterialOrdersTitleActions />}
          title={currentMaterial.name}
          {...pageDataTestId(RouteName.MATERIAL_ORDERS)}
        >
          <Stack spacing={2}>
            <DetailedStockDevelopmentCard />
            <ManageOrdersSection />
          </Stack>
          <CreateEditMaterialOrderDialog />
        </PlannerPageLayout>
      </SelectedMaterialOrderProvider>
    </SelectedTimeframeProvider>
  )
}
