import {dataTestId, useTranslation} from '@hconnect/uikit'
import {Box} from '@mui/material'
import {Moment} from 'moment-timezone'
import React, {useLayoutEffect, useRef} from 'react'

import {DatetimeValueChartYLabels} from '../../../../../shared/components/d3chart/DatetimeValueChartYLabels'
import {moveElementsHorizontally} from '../../../../../shared/components/ganttChart/chart.helpers'
import {StockDevelopmentChartConfigProvider} from '../../../../../shared/components/stockDevelopmentChart/StockDevelopmentChartConfigProvider'
import {StockDevelopmentChartData} from '../../../../../shared/components/stockDevelopmentChart/StockDevelopmentChartData'
import {StockDevelopmentChartDatetimeLabels} from '../../../../../shared/components/stockDevelopmentChart/StockDevelopmentChartDatetimeLabels'
import {useStockDevelopmentChartConfigData} from '../../../../../shared/components/stockDevelopmentChart/utils/useStockDevelopmentChartConfigData'
import {StockDevelopmentEntry} from '../../../../../shared/interfaces/api'
import {DatetimeValue} from '../../../../../shared/interfaces/common'
import type {IncomingMaterial} from '../../../../../shared/selectors/materialOrders'

interface StockDevelopmentChartProps {
  timezoneId: string
  listOfDays: Moment[]
  stockData: {
    stockLevels: StockDevelopmentEntry & {combined: DatetimeValue[]}
    stockLevelsHourly: Record<string, number>
  }
  height: number
  minTargetLevels?: {weekend: number; week: number}
  deadStockLevel?: number
  storageCapacity?: number
  yLabelWidth?: number
  incomingMaterialHourly?: Record<string, IncomingMaterial[]>
  cellWidth: number
  totalSteps: number
  immediateHourOffset: number
}

export const DetailedStockDevelopmentChart: React.FC<StockDevelopmentChartProps> = ({
  timezoneId,
  listOfDays,
  stockData,
  minTargetLevels,
  deadStockLevel,
  storageCapacity,
  yLabelWidth,
  incomingMaterialHourly,
  cellWidth,
  height,
  totalSteps,
  immediateHourOffset
}) => {
  const chartRef = useRef<HTMLDivElement>(null)
  const chartScrollRef = useRef<HTMLDivElement>(null)

  const width = totalSteps * cellWidth

  const stockDevelopmentData = useStockDevelopmentChartConfigData({
    stockData,
    listOfDays,
    timezoneId,
    minTargetLevels,
    storageCapacity,
    height,
    width
  })
  const {t} = useTranslation()

  const {formatDatetime, yScale, verticalSteps} = stockDevelopmentData

  // we need this effect to move the charts horizontally when the slider is moved
  useLayoutEffect(() => {
    moveElementsHorizontally(cellWidth * immediateHourOffset, [chartScrollRef])
  }, [cellWidth, immediateHourOffset])

  return (
    <StockDevelopmentChartConfigProvider {...stockDevelopmentData}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row'
        }}
      >
        <Box
          sx={{
            width: ({spacing}) => spacing(15),
            pr: 1,
            flexShrink: 0
          }}
        >
          <DatetimeValueChartYLabels
            ySteps={verticalSteps}
            yScale={yScale}
            yTitle={t('stock.materialOrders.stockTons')}
            width={yLabelWidth}
          />
        </Box>

        <Box sx={{overflow: 'hidden', width: {width: '100%'}}}>
          <div ref={chartScrollRef}>
            <Box sx={{position: 'relative'}} {...dataTestId('stock_development_chart')}>
              <StockDevelopmentChartDatetimeLabels
                xSteps={listOfDays}
                hideYAxis={true}
                yLabelWidth={yLabelWidth}
                timezoneId={timezoneId}
                formatDatetime={formatDatetime}
              >
                <StockDevelopmentChartData
                  listOfDays={listOfDays}
                  stockData={stockData}
                  minTargetLevels={minTargetLevels}
                  deadStockLevel={deadStockLevel}
                  storageCapacity={storageCapacity}
                  height={height}
                  width={width}
                  chartRef={chartRef}
                  incomingMaterialHourly={incomingMaterialHourly}
                />
              </StockDevelopmentChartDatetimeLabels>
            </Box>
          </div>
        </Box>
      </Box>
    </StockDevelopmentChartConfigProvider>
  )
}
