import {dataTestId} from '@hconnect/uikit'
import {Box, Stack, useTheme} from '@mui/material'
import React, {useCallback, useLayoutEffect, useRef} from 'react'

import {moveElementsHorizontally} from '../../shared/components/ganttChart/chart.helpers'
import {useGanttChartCells} from '../../shared/components/ganttChart/useGanttChartCells'
import {ScheduleCostAvoidanceDialogProvider} from '../../shared/components/providers/ScheduleCostAvoidanceDialogProvider'
import {SelectedAssetCommentProvider} from '../../shared/components/providers/SelectedAssetCommentProvider'
import {CommentsCategory, PlannerFeature} from '../../shared/enums'
import {useDateScale} from '../../shared/helpers/scale'
import {calculateRangeWithinBoundary} from '../../shared/helpers/utils'
import {useCommentsPermissions} from '../../shared/hooks/permissions'
import {useElementWidth} from '../../shared/hooks/useElementWidth'
import {useMediaQueryBreakpoints} from '../../shared/hooks/useMediaQueryBreakpoints'
import {usePlannerFeature} from '../../shared/hooks/usePlannerFeature'
import {useScrollDrag} from '../../shared/hooks/useScrollDrag'

import {CostAvoidanceDialog} from './dailyPlanning/costAvoidance/CostAvoidanceDialog'
import {PlanningElectricityChartCard} from './dailyPlanning/electricity-chart/PlanningElectricityChartCard'
import {KPIsSection} from './dailyPlanning/kpi-section/KPIsSection'
import {usePlanningChartScheduleData} from './dailyPlanning/PlanningChartScheduleDataProvider'
import {usePlanningChartStartEnd} from './dailyPlanning/PlanningChartStartEndProvider'
import {ProductionPlanningChartCard} from './dailyPlanning/scheduleCard/PlanningChartCard'
import {StockLevelsCard} from './dailyPlanning/stockLevels/StockLevelsCard'
import {ProductionPlanningSlider} from './ProductionPlanningSlider'

const _ProductionPlanningChartContainer: React.FC = () => {
  const {spacing} = useTheme()
  const {sm: isMobile} = useMediaQueryBreakpoints()
  const {hoursRange, setHoursRange, hoursList, stepsPerHour, totalSteps, startOfPlan, endOfPlan} =
    usePlanningChartStartEnd()
  const {schedule} = usePlanningChartScheduleData()

  const areCommentsEnabled = usePlannerFeature(PlannerFeature.PlanningComments)
  const {canViewComments: canViewScheduleItemComments} = useCommentsPermissions(
    CommentsCategory.AssetOperationTimes
  )
  const {canViewComments: canViewAssetComments} = useCommentsPermissions(CommentsCategory.Assets)
  const shouldShowScheduleItemComments = areCommentsEnabled && canViewScheduleItemComments
  const shouldShowAssetComments = areCommentsEnabled && canViewAssetComments

  const [planningLayoutWrapperRef, planningLayoutWidth] = useElementWidth()
  const cardXPadding = parseInt(spacing(isMobile ? 2 : 3))
  // calculating available width for gantt and electricity chart
  const availableWidth = planningLayoutWidth > 0 ? planningLayoutWidth - 2 * cardXPadding : 0
  // constants for layout of the Gantt chart
  const labelColumnWidth = isMobile ? 100 : 120
  const dateLabelHeight = 24
  const hourlyPriceHeight = 30
  const cellHeight = 50
  const chartWidth = availableWidth === 0 ? 0 : availableWidth - labelColumnWidth
  const {updateGanttCells, cellWidth, cellsDisplayed} = useGanttChartCells({
    hoursDisplayed: hoursRange[1] - hoursRange[0],
    cellsPerHour: stepsPerHour,
    chartWidth
  })

  const ganttChartRef = useRef<HTMLDivElement>(null)
  const dragScrollWrapperRef = useRef<HTMLDivElement>(null)

  const [immediateHourOffset] = hoursRange

  // Horizontal time scale
  const xScale = useDateScale({
    domain: [startOfPlan, endOfPlan],
    range: [0, cellWidth * totalSteps]
  })

  const updateHourRangeOnScrollOrDrag = useCallback(
    (delta: number) => {
      const currentOffset = immediateHourOffset + delta
      const newRange = calculateRangeWithinBoundary(
        [currentOffset, currentOffset + cellsDisplayed],
        [0, totalSteps]
      )
      setHoursRange(newRange)
    },
    [immediateHourOffset, cellsDisplayed, setHoursRange, totalSteps]
  )
  // we need this effect to move the charts horizontally when the slider is moved
  useLayoutEffect(() => {
    moveElementsHorizontally(cellWidth * immediateHourOffset, [ganttChartRef])
  }, [cellWidth, immediateHourOffset])

  useScrollDrag({
    ref: dragScrollWrapperRef,
    callback: updateHourRangeOnScrollOrDrag,
    stepWidth: cellWidth,
    multiplier: 4,
    isDragEnabled: false
  })

  return (
    <Box {...dataTestId('planning_chart_container')} ref={planningLayoutWrapperRef}>
      <Stack spacing={2}>
        <ProductionPlanningSlider updateGanttCells={updateGanttCells} />
        <StockLevelsCard
          startOfPlan={startOfPlan}
          endOfPlan={endOfPlan}
          cellWidth={cellWidth}
          totalSteps={totalSteps}
          immediateHourOffset={immediateHourOffset}
        />
        <Stack spacing={2} ref={dragScrollWrapperRef} {...dataTestId('chart_scroll_drag_wrapper')}>
          <ScheduleCostAvoidanceDialogProvider>
            {schedule?.scheduleId !== undefined && (
              <CostAvoidanceDialog scheduleId={schedule.scheduleId} />
            )}
            <SelectedAssetCommentProvider>
              <ProductionPlanningChartCard
                ref={ganttChartRef}
                startOfPlan={startOfPlan}
                endOfPlan={endOfPlan}
                labelColumnWidth={labelColumnWidth}
                dateLabelHeight={dateLabelHeight}
                hourlyPriceHeight={hourlyPriceHeight}
                cellHeight={cellHeight}
                cellWidth={cellWidth}
                hoursDisplayed={cellsDisplayed}
                xScale={xScale}
                hoursList={hoursList}
                shouldShowScheduleItemComments={shouldShowScheduleItemComments}
                shouldShowAssetComments={shouldShowAssetComments}
                scheduleLastSaved={schedule?.lastSaved}
              />
            </SelectedAssetCommentProvider>
            <PlanningElectricityChartCard
              startOfPlan={startOfPlan}
              endOfPlan={endOfPlan}
              hoursList={hoursList}
              cellWidth={cellWidth}
              labelColumnWidth={labelColumnWidth}
              cellsDisplayed={cellsDisplayed}
            />
          </ScheduleCostAvoidanceDialogProvider>
        </Stack>
        <KPIsSection />
      </Stack>
    </Box>
  )
}

export const ProductionPlanningChartContainer = React.memo(_ProductionPlanningChartContainer)
