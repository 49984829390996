import {CommentsCategory} from '../../../enums'
import {useUrlParam} from '../../useUrlParam'
import {usePlannerQuery} from '../usePlannerQuery'

export const useScheduleCommentsQuery = (scheduleItemsIds: string[], isDisabled = false) => {
  const plantCode = useUrlParam('plantCode')

  return usePlannerQuery(
    'comments',
    [
      {
        plantCode,
        commentsCategory: CommentsCategory.AssetOperationTimes,
        commentsCategoryInstanceIds: scheduleItemsIds
      }
    ],
    {
      enabled: scheduleItemsIds.length > 0 && !isDisabled
    }
  )
}
