import {dataTestId} from '@hconnect/uikit'
import {Skeleton} from '@mui/material'

import {HCEMPalette} from '../../pallette'
import {PlannerPageLayout} from '../PlannerPageLayout'

export const PlannerPageSkeleton = () => {
  return (
    <PlannerPageLayout
      {...dataTestId('planner_page_skeleton')}
      isTourBannerEnabled={false}
      title={
        <Skeleton
          variant="rectangular"
          sx={{
            width: (theme) => theme.spacing(50),
            backgroundColor: HCEMPalette.legacyBackgroundDefault
          }}
        />
      }
    >
      <Skeleton
        variant="rectangular"
        sx={{
          height: '65vh',
          backgroundColor: HCEMPalette.legacyBackgroundDefault
        }}
      />
    </PlannerPageLayout>
  )
}
