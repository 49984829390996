import {useNotification} from '@hconnect/uikit/src/common'
import {useMutation} from '@tanstack/react-query'
import {AxiosError} from 'axios'
import {useMemo} from 'react'
import {useErrorHandler} from 'react-error-boundary'

import {mutations} from '../../../api/mutations'
import {notifyIfErrorMessage} from '../../../common'
import {hcemQueryClient} from '../../../components/providers/QueryProvider'
import {CommentsCategory} from '../../../enums'
import {trackPlannerEvent} from '../../../helpers/trackPlannerEvents'
import {ErrorResponse} from '../../../interfaces/api'

export const useUpdateScheduleComment = () => {
  const {notify} = useNotification()
  const raiseError = useErrorHandler()
  const {mutate, isLoading: isUpdateCommentLoading} = useMutation<
    void,
    AxiosError<ErrorResponse>,
    Parameters<typeof mutations.editComment>[0]
  >({
    mutationFn: mutations.editComment,
    mutationKey: ['updateScheduleComment'],
    onError: (error) => {
      notifyIfErrorMessage(error.response?.data.detail, raiseError, notify)
    },
    onSettled: (data, error, variables) => {
      trackPlannerEvent('onCommentEditToScheduleItem', {plantId: variables.plantCode})
      void hcemQueryClient.invalidateQueries({
        queryKey: ['comments', {commentsCategory: CommentsCategory.AssetOperationTimes}]
      })
    }
  })

  const updateScheduleComment = useMemo(
    () => (params: Omit<Parameters<typeof mutations.editComment>[0], 'commentsCategory'>) =>
      mutate({...params, commentsCategory: CommentsCategory.AssetOperationTimes}),
    [mutate]
  )

  return {
    updateScheduleComment,
    isUpdateCommentLoading
  }
}
